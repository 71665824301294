import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarModule } from './snackbar.module';
import { ErrorSnackComponent } from './error-snack/error-snack.component';
import { SuccessSnackComponent } from './success-snack/success-snack.component';
import { InfoSnackComponent } from './info-snack/info-snack.component';
import { WarningSnackComponent } from './warning-snack/warning-snack.component';

interface SnackbarMessage {
    title: string;
    message: string;
    duration?: number;
    horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right';
    verticalPosition?: 'top' | 'bottom';
}

@Injectable({
    providedIn: SnackbarModule,
})
export class SnackbarService {
    constructor(private snackbar: MatSnackBar) {}

    error(message: string, title: string = 'Error') {
        this.snackbar.openFromComponent(ErrorSnackComponent, {
            data: { title, message },
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'p-0',
        });
    }

    success(message: string, title: string = 'Success') {
        this.snackbar.openFromComponent(SuccessSnackComponent, {
            data: { title, message },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'p-0',
        });
    }

    info(message: string, title: string = 'Info') {
        this.snackbar.openFromComponent(InfoSnackComponent, {
            data: { title, message },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'p-0',
        });
    }

    warning(snackBarOptions: Partial<SnackbarMessage> = {}): void {
        const options = Object.assign(
            {
                title: 'Warning',
                message: '',
                duration: 3000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
            },
            snackBarOptions
        );

        this.snackbar.openFromComponent(WarningSnackComponent, {
            data: { title: options.title, message: options.message },
            duration: options.duration,
            horizontalPosition: options.horizontalPosition,
            verticalPosition: options.verticalPosition,
            panelClass: 'p-0',
        });
    }
}
