import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { Brand } from 'app/models/brand';
import { Document } from 'app/models/documents';
import { map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BrandService {
    constructor(private firestore: AngularFirestore) {}

    // method that crates a new sub-collection in the user document
    // and adds a new document to that sub-collection
    async createBrandSubCollection(userId: string, data: any): Promise<any> {
        this.firestore
            .collection('users')
            .doc(userId)
            .collection('brand')
            .add({
                ...data,
            });
    }

    getBrandCollection(userId: string): Observable<Brand[]> {
        return this.firestore
            .collection('users')
            .doc(userId)
            .collection('brand')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Document>[]) =>
                    actions.map((action: DocumentChangeAction<Document>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as Brand;
                    })
                )
            );
    }

    getBrandDocument(userId: string, documentId: string) {
        return this.firestore
            .collection('users')
            .doc(userId)
            .collection('brand')
            .doc(documentId)
            .get()
            .pipe(map(doc => doc.data()));
    }

    async updateBrandDocument(
        userId: string,
        documentId: string = null,
        data: any
    ) {
        //Inform coach of new starter form completed.
        const batch = this.firestore.firestore.batch();

        if (!Object.keys(data).length) {
            return;
        }

        const docId = documentId ?? this.firestore.createId();
        data.id = docId;

        const brandRef = this.firestore
            .collection('users')
            .doc(userId)
            .collection('brand')
            .doc(docId).ref;

        // Save the Coach
        batch.set(
            brandRef,
            {
                ...data,
            },
            { merge: true }
        );

        // Save the form
        return batch
            .commit()
            .then(res => brandRef.id)
            .catch((error) => {
                console.log('An error occured: ', error);
            });
    }
}
