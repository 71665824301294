import { Injectable } from '@angular/core';
import {
    Action,
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { Client } from 'app/models/client';
import { User } from 'app/models/user';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { PlatformNotifications } from 'app/layout/common/notifications/notifications.models';
import { increment } from '@angular/fire/firestore';
import { UserType } from 'app/enums/user-type.enum';
import { Coach, CoachMember } from 'app/models/coach';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private _selectedClient: BehaviorSubject<Client | null> =
        new BehaviorSubject(null);
    public _currentUser: BehaviorSubject<User | null> = new BehaviorSubject(
        null
    );

    constructor(
        private firestore: AngularFirestore,
        private fns: AngularFireFunctions,
        private _authService: AuthService
    ) {}

    set currentUser(user: any) {
        this._currentUser.next(user);
    }

    get currentUser$() {
        return this._currentUser.asObservable();
    }

    getUsersByType(userType = UserType.CLIENT): Observable<Client[]> {
        return this.firestore
            .collection('users', (ref) =>
                ref.where('type', '==', userType).orderBy('firstName', 'asc')
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Client>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        }
                    );
                })
            );
    }

    getClient(clientId: string): Observable<Client> {
        return this.firestore
            .collection('users')
            .doc<Client>(clientId)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data = a?.payload?.data();
                        const id = a?.payload?.id;

                        const user = { id, ...data } as Client;
                        this._selectedClient.next(user);

                        return user;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    getClientOnce(clientId: string): Promise<Client> {
        return this.firestore
            .collection('users')
            .doc<Client>(clientId)
            .get()
            .toPromise();
    }

    getCoach(coachId: string) {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data = a?.payload?.data();
                        const id = a?.payload?.id;

                        const user = { id, ...data } as Coach;
                        this._selectedClient.next(user);

                        return user;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    getClientOnceSub(clientId: string): Observable<Client> {
        return this.firestore.collection('users').doc<Client>(clientId).get();
    }

    addStarterForm(currentUser: Client, starterFormId: string) {
        //Inform coach of new starter form completed.
        const batch = this.firestore.firestore.batch();

        const userProfile = this.firestore
            .collection('users')
            .doc(currentUser?.id).ref;
        const notificationRef = this.firestore
            .collection('notifications')
            .doc().ref;

        //Save starter form.
        batch.set(
            userProfile,
            {
                starterFormId: starterFormId,
                completeStarterForm: false,
                currentWeight: null,
            },
            { merge: true }
        );

        const starterFormNotification: Partial<PlatformNotifications> = {
            read: false,
            created: new Date(),
            description: `${currentUser.firstName} ${currentUser.lastName} has just completed their starter form.`,
            icon: 'heroicons_outline:document-add',
            link: `/clients/${currentUser?.id}`,
            userId: currentUser.coachId ?? null,
            title: `New Starter Form`,
        };

        //Create the notification for the coach.
        batch.set(notificationRef, starterFormNotification);

        return batch.commit();
    }

    addUsers(client: Client) {
        const inviteUsers = this.fns.httpsCallable('inviteUsers');
        return inviteUsers(client)
            .toPromise()
            .then(() => {})
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    validateInviteToken(token: string) {
        const validate = this.fns.httpsCallable('validateInviteCode');
        return validate({ inviteCode: token })
            .toPromise()
            .then((res) => {
                console.log();
            })
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    getClientsToRenew() {
        return this.firestore
            .collection('users', (ref) => ref.where('upForRenewal', '==', true))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Client>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as Client;
                        }
                    );
                })
            );
    }

    extendUsersPlan(
        clientId: string,
        checkInDay: number,
        noOfWeeks: number
    ): Promise<any> {
        const extendUsersPlan = this.fns.httpsCallable('extendUsersPlan');
        return extendUsersPlan({
            clientId: clientId,
            noOfWeeks: noOfWeeks,
            checkInDay: checkInDay,
        })
            .toPromise()
            .then(() => {})
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    updateTotalPTSessionsRemaining(
        clientId: string,
        totalPtSessionsToAdd: number
    ) {
        return this.firestore
            .collection('users')
            .doc(clientId)
            .set(
                {
                    remainingSessions: increment(totalPtSessionsToAdd),
                },
                { merge: true }
            );
    }

    deleteTeamMember(orgId: string, teamMemberId: string) {
        const deleteTeamMember = this.fns.httpsCallable('deleteTeamMember');

        return deleteTeamMember({ orgId: orgId, coachId: teamMemberId })
            .toPromise()
            .then((res) => {})
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    inviteTeamMember(member: CoachMember) {
        const profile = {
            ...member,
            joined: new Date(),
            type: UserType.COACH,
        };

        const inviteCoach = this.fns.httpsCallable('inviteTeamMember');

        return inviteCoach(profile)
            .toPromise()
            .then((res) => {})
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    async updatePermissions(orgId: string, memberId: string, role: string) {
        const batch = this.firestore.firestore.batch();

        const teamMemberRef = this.firestore
            .collection('users')
            .doc(orgId)
            .collection('coaches')
            .doc(memberId).ref;
        const coachDocRef = this.firestore
            .collection('users')
            .doc(memberId).ref;

        batch.set(teamMemberRef, { role: role }, { merge: true });
        batch.set(coachDocRef, { role: role }, { merge: true });

        return await batch.commit();
    }

    async updateTeamMembersName(
        orgId: string,
        coachId: string,
        firstName: string,
        lastName: string
    ) {
        return await this.firestore
            .collection('users')
            .doc(orgId)
            .collection('coaches')
            .doc(coachId)
            .set(
                { profile: { firstName: firstName, lastName: lastName } },
                { merge: true }
            )
            .then((success) => {
                return true;
            });
    }

    changeCoach(orgId: string, clientId: string, coachId: string) {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('users')
            .doc(clientId)
            .set({ coachedBy: coachId }, { merge: true });
    }

    inviteCoach(
        auth: { email: string; password: string; inviteCode: string },
        coachDetails: any
    ) {
        const profile = {
            ...coachDetails,
            joined: new Date(),
            type: UserType.COACH,
            profileComplete: false,
        };

        const inviteCoach = this.fns.httpsCallable('inviteCoach');

        return inviteCoach({ auth: auth, profile: profile })
            .toPromise()
            .then((res) => {})
            .catch((error) => {
                console.error(error);
                throw new Error(error);
            });
    }

    getCoachOnce(coachId: string): Promise<Client> {
        return this.firestore
            .collection('users')
            .doc<Client>(coachId)
            .get()
            .toPromise();
    }

    getCoachOnceSub(coachId: string): Observable<Client> {
        return this.firestore.collection('users').doc<Client>(coachId).get();
    }

    getCoachCollection(
        coachId: string,
        collectionName: string = 'clients'
    ): Observable<any[]> {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection(collectionName)
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<Client>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as any;
                        }
                    );
                })
            );
    }

    async updateClient(clientDetails: Partial<Client>, data: any) {
        const batch = this.firestore.firestore.batch();

        const clientRef = this.firestore
            .collection('users')
            .doc(clientDetails.coachId)
            .collection('users')
            .doc(clientDetails.uid).ref;

        batch.set(
            clientRef,
            {
                ...data,
                updated: new Date(),
            },
            { merge: true }
        );

        // Save the coaches profile
        return batch.commit().catch((error) => {
            console.log('An error occured: ', error);
        });
    }

    // Update the coaches profile
    async updateCoach(coachDetails: Partial<User>) {
        const batch = this.firestore.firestore.batch();

        // Save the Coach Profile
        const coachProfileRef = this.firestore
            .collection('users')
            .doc(coachDetails.uid).ref;

        batch.set(
            coachProfileRef,
            {
                profile: coachDetails.profile,
                // publicLink: formatUsername(coachDetails?.profile?.userName),
                profileComplete: true,
            },
            { merge: true }
        );

        // Save the coaches profile
        try {
            return await batch
                .commit()
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    console.log('An error occured: ', error);
                    return false;
                });
        } catch (error) {
            console.log('An error occured: ', error);
        }
    }

    // Update the coaches profile with property
    async updateCoachWithData(coachId: string, data: any) {
        const batch = this.firestore.firestore.batch();

        // Save the Coach Profile
        const coachProfileRef = this.firestore
            .collection('users')
            .doc(coachId).ref;

        batch.set(
            coachProfileRef,
            {
                ...data,
                updated: new Date(),
            },
            { merge: true }
        );

        // Save the coaches profile
        try {
            return await batch
                .commit()
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    console.log('An error occured: ', error);
                    return false;
                });
        } catch (error) {
            console.log('An error occured: ', error);
        }
    }

    // ? How do we handle special charcters and capitals in username?
    isUserNameTaken(userName: string): Observable<boolean> {
        if (!userName) return of(false);

        return this.firestore
            .collection('usernames')
            .doc<any>(formatUsername(userName))
            .get()
            .pipe(map((doc) => doc.exists));
    }

    // get coaches clients
    getClients(
        orgId: string,
        coachId: string,
        isAdmin: boolean = true
    ): Observable<Client[]> {
        if (isAdmin) {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users')
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<any>[]) => {
                        return actions.map(
                            (action: DocumentChangeAction<Client>) => {
                                const data: any = action.payload.doc.data();
                                const id: string = action.payload.doc.ref.id;
                                return { id, ...data } as Client;
                            }
                        );
                    })
                );
        } else {
            return this.firestore
                .collection('users')
                .doc(orgId)
                .collection('users', (ref) =>
                    ref.where('coachedBy', '==', coachId)
                )
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<any>[]) => {
                        return actions.map(
                            (action: DocumentChangeAction<Client>) => {
                                const data: any = action.payload.doc.data();
                                const id: string = action.payload.doc.ref.id;
                                return { id, ...data } as Client;
                            }
                        );
                    })
                );
        }
    }

    // get single user - client or coach
    getUser(userId: string): Observable<Client | Coach> {
        return this.firestore
            .collectionGroup('users', (ref) =>
                ref.where('uid', '==', userId).limit(1)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Client>[]) => {
                    if (actions?.length > 0) {
                        const user = actions[0];
                        const data: any = user.payload.doc.data();
                        const id: string = user.payload.doc.ref.id;
                        this._currentUser.next({ id, ...data });
                        return { id, ...data } as Client | Coach;
                    } else {
                        return null;
                    }
                })
            );
    }
}

const formatUsername = (username: string) => {
    if (!username) return null;
    return username.trim().toLowerCase().replace(/\W+/g, '-');
};
