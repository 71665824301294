<button
    mat-fab
    [matMenuTriggerFor]="actionsMenu"
    color="primary"
    class="md:hidden fixed bottom-16 right-6 z-99"
>
    <mat-icon>{{ icon }}</mat-icon>
</button>
<mat-menu #actionsMenu="matMenu" class="mb-2">
    <ng-content></ng-content>
</mat-menu>
