<div
    class="flex flex-col gap-3 w-full h-full p-6 justify-center items-center"
>
    <mat-icon *ngIf="icon" [svgIcon]="icon" class="icon-size-10"></mat-icon>
    <h3 class="text-3xl text-gray-500">{{ title }}</h3>
    <p *ngIf="subtitle" class="text-secondary text-center max-w-80">
        {{ subtitle }}
    </p>
    <ng-content></ng-content>
</div>
