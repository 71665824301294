import { Pipe, PipeTransform } from '@angular/core';
import { DurationType } from 'app/models/duration';

@Pipe({ name: 'durationPipe' })
export class DurationPipe implements PipeTransform {
    transform(status: number): string {
        switch (status) {
            case DurationType.DAYS:
                return 'days';

            case DurationType.MONTHS:
                return 'months';

            case DurationType.WEEKS:
                return 'weeks';

            default:
                return 'Unknown';
        }
    }
}
