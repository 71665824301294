<div
    class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6 overflow-y-auto"
    *ngIf="form"
>
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
    >
        <div class="text-lg font-medium">Add Price</div>
        <button mat-icon-button (click)="matDialogRef.close()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"
            ></mat-icon>
        </button>
    </div>

    <form
        *ngIf="form"
        [formGroup]="form"
        class="flex flex-col px-8 pt-10 bg-card shadow rounded overflow-hidden"
    >
        <!-- Price -->
        <mat-form-field class="flex-auto w-full fuse-mat-emphasized-affix">
            <mat-label>Price</mat-label>

            <input
                matInput
                [formControlName]="'amount'"
                type="number"
                min="0"
            />
            <mat-error *ngIf="form.get('amount').hasError('required')">
                Amount is required
            </mat-error>
            <mat-select [value]="'GBP'" matPrefix>
                <mat-option [value]="'GBP'">£</mat-option>
            </mat-select>
            <mat-select
                [value]="'GBP'"
                matSuffix
                [formControlName]="'paymentFrequency'"
            >
                <mat-option
                    [value]="period"
                    *ngFor="let period of reoccuringPaymentFrequency"
                    >{{ period.label }}</mat-option
                >
            </mat-select>
        </mat-form-field>

        <!-- <div class="flex">
            <mat-form-field class="flex-auto">
                <mat-button-toggle-group
                    single
                    [value]="paymentFrequencies[0].value"
                    [formControlName]="'paymentFrequency'"
                >
                    <input matInput hidden />
                    <mat-button-toggle
                        class="gap-2"
                        [value]="option.value"
                        *ngFor="let option of paymentFrequencies"
                    >
                        {{ option.label }}</mat-button-toggle
                    >
                </mat-button-toggle-group>
            </mat-form-field>
        </div> -->

        <!-- <div class="flex w-full" *ngIf="showReccuring">
            <mat-form-field class="flex-auto">
                <mat-label>Billing period</mat-label>

                <mat-select [formControlName]="'reccuringPeriod'">
                    <mat-option
                        [value]="period"
                        *ngFor="let period of reoccuringPaymentFrequency"
                        >{{ period.label }}</mat-option
                    >
                </mat-select>
                <mat-error
                    *ngIf="form.get('reccuringPeriod').hasError('required')"
                >
                    Billing period is required
                </mat-error>
            </mat-form-field>
        </div> -->

        <div class="w-full">
            <div
                class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5 bg-gray-50 dark:bg-gray-700"
            >
                <button type="button" mat-button (click)="matDialogRef.close()">
                    Cancel
                </button>
                <button
                    class="px-8"
                    mat-flat-button
                    [disabled]="form.invalid || loading"
                    [color]="'primary'"
                    (click)="save()"
                    type="button"
                >
                    <span *ngIf="!loading">Save</span>
                    <mat-progress-spinner
                        [diameter]="24"
                        [mode]="'indeterminate'"
                        *ngIf="loading"
                    >
                    </mat-progress-spinner>
                </button>
            </div>
        </div>
    </form>
</div>
