<div class="relative flex flex-col flex-auto min-w-0 overflow-hidden">
    <div
        class="flex flex-col sm:flex-row items-start sm:items-center sm:justify-between p-6 sm:py-12 md:px-8 border-b bg-card dark:bg-transparent"
    >
        <!-- Title -->
        <div>
            <div>
                <div
                    class="text-4xl font-extrabold tracking-tight leading-tight"
                >
                    Services
                </div>
                <div class="mt-2 text-xl text-secondary">
                    Manage the services you offer your clients including
                    duration of plan, prices and subscriptions.
                </div>
            </div>
            <div
                class="flex items-center mt-0.5 font-medium text-secondary"
            ></div>
        </div>
        <!-- Actions -->
        <div class="mt-4 sm:mt-0" *ngIf="isAdmin">
            <button mat-flat-button [color]="'primary'" (click)="addService()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
        </div>
    </div>

    <!-- <div
        class="px-6 py-10 sm:py-12 sm:px-16 bg-primary-600 text-on-primary-600"
    >
        <div
            class="flex flex-col items-center w-full max-w-7xl mx-auto text-center"
        >
            <div
                class="text-3xl sm:text-4xl sm:text-5xl font-extrabold leading-6 sm:leading-10"
            >
                Import Stripe Products
            </div>
            <div
                class="mt-2 text-3xl sm:text-4xl sm:text-5xl font-extrabold leading-6 sm:leading-10 text-black text-opacity-70"
            >
                You have <strong>10</strong> products available in Stripe to
                import.
            </div>
            <button
                mat-flat-button
                class="fuse-mat-button-large mt-8 px-12 bg-white text-primary-800"
            >
                <span class="text-lg">Import Products</span>
            </button>
        </div>
    </div> -->

    <ng-container *ngIf="loading; else finishedLoading">
        <div
            class="flex items-center justify-center w-full h-full"
            *ngIf="loading"
        >
            <mat-progress-spinner [mode]="'indeterminate'">
            </mat-progress-spinner>
        </div>
    </ng-container>

    <ng-template #finishedLoading>
        <ng-container *ngIf="services?.length > 0; else noServices">
            <!-- Header & Cards -->
            <div class="relative pb-12 sm:pb-24 px-6 sm:px-16 overflow-hidden">
                <!-- Cards -->
                <div class="flex justify-center mt-10 sm:mt-20">
                    <div class="w-full max-w-sm md:max-w-7xl">
                        <!-- Show alert -->
                        <fuse-alert
                            *ngIf="!stripeEnabled && isAdmin"
                            class="mb-2"
                            [appearance]="'outline'"
                            [showIcon]="true"
                            [type]="'info'"
                        >
                            Stripe account not connected so unable to take
                            online payments. Please connect in
                            <strong>Account > Security and Settings. </strong>
                        </fuse-alert>

                        <div
                            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 lg:gap-y-0 md:gap-x-6"
                        >
                            <service-card
                                [service]="service"
                                [orgId]="orgId"
                                [isAdmin]="isAdmin"
                                [stripeEnabled]="stripeEnabled"
                                (editService)="edit(service)"
                                *ngFor="let service of services"
                            ></service-card>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noServices>
            <div
                class="flex flex-col gap-1 w-full h-full p-6 justify-center items-center"
            >
                <h3 class="text-3xl text-gray-400">No services found.</h3>
                <p class="text-secondary">
                    You don't have any services plans. Add a new service to
                    start inviting clients.
                </p>
                <button
                    *ngIf="isAdmin"
                    mat-raised-button
                    color="primary"
                    class="mt-4"
                    (click)="addService()"
                >
                    <mat-icon class="mr-2">add</mat-icon>
                    Add
                </button>
            </div>
        </ng-template>
    </ng-template>
</div>
