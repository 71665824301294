<div class="flex flex-col min-w-0 w-full h-full">
    <mat-drawer-container
        class="flex-auto h-full bg-card dark:bg-transparent"
        (backdropClick)="onBackdropClicked()"
    >
        <!-- Drawer -->
        <mat-drawer
            class="w-full sm:w-100 dark:bg-gray-900"
            [mode]="drawerMode"
            [opened]="false"
            [position]="'end'"
            [disableClose]="true"
            #matDrawer
        >
            <router-outlet></router-outlet>
        </mat-drawer>

        <mat-drawer-content
            class="flex flex-col bg-gray-100 dark:bg-transparent h-full"
        >
            <!-- Main -->
            <div class="flex flex-col flex-auto h-full">
                <div class="flex flex-wrap w-full mx-auto">
                    <!-- Title and action buttons -->
                    <div
                        class="flex flex-col md:flex-row md:items-center justify-between w-full p-2 pb-0 py-4 px-6"
                    >
                        <h2
                            class="text-3xl font-semibold tracking-tight leading-8"
                        >
                            Exercise Log
                        </h2>
                    </div>

                    <!-- Fab icon / menu -->
                    <quick-actions icon="fitness_center" *ngIf="showFabIcon">
                        <button
                            mat-menu-item
                            [routerLink]="['./search/exercises']"
                        >
                            <mat-icon
                                [svgIcon]="'heroicons_outline:search'"
                            ></mat-icon>
                            <span class="ml-2 mr-1">Add Exercise</span>
                        </button>
                        <button
                            *ngIf="!session?.ended && session?.id"
                            mat-menu-item
                            (click)="showTimer(true)"
                        >
                            <mat-icon
                                [svgIcon]="'heroicons_outline:clock'"
                            ></mat-icon>
                            <span class="ml-2 mr-1">Timer</span>
                        </button>
                        <button
                            *ngIf="!session?.ended && session?.id"
                            mat-menu-item
                            (click)="endSession1()"
                            [matTooltip]="'End Workout'"
                        >
                            <mat-icon
                                [svgIcon]="'heroicons_outline:pause'"
                            ></mat-icon>
                            <span class="ml-2">End Workout</span>
                        </button>
                        <button
                            *ngIf="!session || !session?.id"
                            mat-menu-item
                            (click)="startSession()"
                            [matTooltip]="'Start Workout'"
                        >
                            <mat-icon
                                [svgIcon]="'heroicons_outline:play'"
                            ></mat-icon>
                            <span>Select Workout</span>
                        </button>
                    </quick-actions>
                </div>

                <ng-container *ngIf="selectSession; else displayCurrentSession">
                    <!-- Show preview if plan type is not calendar as they can select whichever day they want. Only show preview for calendar if session planned for today -->
                    <ng-container
                        *ngIf="
                            planType !== 3 ||
                                (planType == 3 &&
                                    todaysSession?.exercises?.length > 0);
                            else noSessionFoundForToday
                        "
                    >
                        <div class="py-4 px-6 flex gap-3 flex-col h-full">
                            <!-- Training sessions to select from -->
                            <div class="flex flex-row gap-2">
                                <!-- Dont let calendar plans select days -->
                                <mat-form-field
                                    class="fuse-mat-rounded w-2/3"
                                    *ngIf="planType !== 3"
                                >
                                    <mat-select
                                        [(value)]="this.todaysSession"
                                        [placeholder]="'Select a routine'"
                                        (selectionChange)="
                                            sessonPreviewChange($event.value)
                                        "
                                    >
                                        <mat-option
                                            *ngFor="
                                                let sesh of trainingPlanSessions;
                                                let i = index
                                            "
                                            [value]="sesh"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    planType === 3;
                                                    else defaultView
                                                "
                                            >
                                                {{
                                                    sesh.sessionId
                                                        | date : "mediumDate"
                                                }}
                                            </ng-container>
                                            <ng-template #defaultView>
                                                {{ sesh.name }}
                                            </ng-template>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div
                                    class="w-1/3"
                                    [ngClass]="{ 'w-full': planType == 3 }"
                                >
                                    <button
                                        mat-flat-button
                                        class="w-full mt-1"
                                        [color]="'primary'"
                                        (click)="beginSession()"
                                        [matTooltip]="'Begin'"
                                    >
                                        <span class="ml-2">Start</span>
                                    </button>
                                </div>
                            </div>

                            <ng-container
                                *ngIf="
                                    previewSession?.exercises?.length > 0;
                                    else noExercisesFound
                                "
                            >
                                <ng-container
                                    *ngIf="planType !== 3; else calendarPlan"
                                >
                                    <fuse-alert [type]="'info'">
                                        Below are the exercises for the selected
                                        day, click start to begin the session.
                                    </fuse-alert>
                                </ng-container>
                                <ng-template #calendarPlan>
                                    <fuse-alert [type]="'info'">
                                        Below are the exercises planned for
                                        today. Click start to begin.
                                    </fuse-alert>
                                </ng-template>

                                <preview-exercise
                                    *ngFor="
                                        let exercise of previewSession?.exercises
                                    "
                                    [title]="exercise?.name"
                                    [sets]="exercise?.sets"
                                    [exercise]="exercise"
                                    [instructions]="exercise.notes"
                                    [exerciseLink]="exercise.exerciseLink"
                                >
                                </preview-exercise>
                            </ng-container>
                            <ng-template #noExercisesFound>
                                <div
                                    class="flex flex-col gap-1 w-full h-full p-6 justify-center items-center"
                                >
                                    <p class="text-secondary">
                                        No exercises found.
                                    </p>
                                </div>
                            </ng-template>
                        </div>
                    </ng-container>
                    <ng-template #noSessionFoundForToday>
                        <empty-state-view
                            class="h-full w-full"
                            [icon]="'mat_outline:fitness_center'"
                            [title]="'No session planned for today'"
                            [subtitle]="'Add an exercise to get started'"
                        >
                            <button
                                mat-raised-button
                                color="primary"
                                class="mt-4"
                                [routerLink]="['./search/exercises']"
                            >
                                <mat-icon class="mr-2">add</mat-icon>
                                Add exercise
                            </button>
                        </empty-state-view>
                    </ng-template>
                </ng-container>

                <ng-template #displayCurrentSession>
                    <div class="w-full px-2" *ngIf="dateOptions?.length > 0">
                        <mat-form-field
                            class="w-full fuse-mat-rounded md:ml-3 mt-3"
                        >
                            <mat-select
                                [value]="this.getDefaultDate()"
                                (selectionChange)="changeDay($event.value)"
                            >
                                <mat-option
                                    [value]="date"
                                    *ngFor="
                                        let date of dateOptions;
                                        let i = index
                                    "
                                >
                                    {{ date | date : "mediumDate" }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-template>

                <!-- Loading -->
                <ng-container *ngIf="loading; else finishedLoading">
                    <div
                        class="flex items-center justify-center w-full h-full"
                        *ngIf="loading"
                    >
                        <mat-progress-spinner [mode]="'indeterminate'">
                        </mat-progress-spinner>
                    </div>
                </ng-container>

                <!-- No items template -->
                <ng-template #finishedLoading>
                    <div class="px-3 flex gap-3 flex-col h-full">
                        <timer
                            class="mt-2"
                            (closed)="showTimer(false)"
                            [open]="displayTimer"
                            *ngIf="displayTimer"
                        ></timer>

                        <ng-container *ngIf="session?.started; else notStarted">
                            <ng-container
                                *ngIf="
                                    session?.started && exercises?.length > 0;
                                    else noExercisesLogged
                                "
                            >
                                <set-card
                                    *ngFor="let exercise of exercises"
                                    [title]="exercise?.loggedExercise?.name"
                                    [sets]="exercise?.loggedExercise?.sets"
                                    [coachProgrammedExercise]="
                                        exercise?.coachProgrammedExercise
                                    "
                                    [exercise]="exercise.loggedExercise"
                                    [canViewExercise]="true"
                                    (deleteExercise)="deleteExercise(exercise)"
                                    (triggerMarkAsCompleted)="
                                        markAsCompleted(exercise, $event)
                                    "
                                    (updatedNoted)="
                                        updateNotes(exercise, $event)
                                    "
                                    [showAddSets]="true"
                                >
                                </set-card>
                            </ng-container>
                            <ng-template #noExercisesLogged>
                                <empty-state-view
                                    class="h-full w-full"
                                    [icon]="'mat_outline:fitness_center'"
                                    [title]="'No exercises found'"
                                    [subtitle]="
                                        'Add an exercise to get started'
                                    "
                                >
                                    <button
                                        mat-raised-button
                                        color="primary"
                                        class="mt-4"
                                        [routerLink]="['./search/exercises']"
                                    >
                                        <mat-icon class="mr-2">add</mat-icon>
                                        Add exercise
                                    </button>
                                </empty-state-view>
                            </ng-template>
                        </ng-container>
                    </div>

                    <ng-template #notStarted>
                        <exercise-log-empty-state
                            class="w-full h-full"
                            (addExercise)="startSession()"
                            *ngIf="!selectSession"
                        ></exercise-log-empty-state>
                    </ng-template>
                </ng-template>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
