import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Exercise, Set } from 'app/models/exercise';
import { ExerciseLogService } from '../exercise-log.service';

@Component({
    selector: 'set-card',
    templateUrl: './set-card.component.html',
    styleUrls: ['./set-card.component.scss'],
})
export class SetCardComponent implements OnInit {
    @Input()
    title: string;

    @Input()
    showTitle: boolean = true;

    @Input()
    sets: Set[];

    @Input()
    openByDefault: boolean = true;

    @Input()
    showActions: boolean = true;

    @Input()
    coachProgrammedExercise: Exercise;

    @Input()
    exercise: Exercise;

    @Input()
    canViewExercise: boolean = false;

    @Input()
    showAddSets: boolean = false;

    @Input()
    addNote: boolean = false;

    @Input()
    showHover: boolean = false;

    @Output()
    deleteExercise: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    setSelectedChange: EventEmitter<number> = new EventEmitter();

    @Output()
    triggerMarkAsCompleted: EventEmitter<boolean> = new EventEmitter();

    @Output()
    updatedNoted: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private _exerciseLogService: ExerciseLogService,
        private _router: Router,
        private _fuseConfirmationService: FuseConfirmationService
    ) {}

    ngOnInit(): void {
        console.log(this.exercise);
    }

    selectSet(index: number) {
        this.setSelectedChange.emit(index);
    }

    showAddNote(show: boolean) {
        this.addNote = show;
    }

    markAsCompleted(complete: boolean) {
        this.triggerMarkAsCompleted.emit(complete);
    }

    viewExercise() {
        if (!this.canViewExercise) {
            return;
        }

        this._exerciseLogService.selectedExercise.next(this.exercise);

        this._router.navigateByUrl(
            `/workouts/exercises/${this.exercise.exerciseId}`
        );
    }

    updateNotes(notes: any) {
        this.updatedNoted.emit(notes?.target?.value ?? null);
    }

    deleteLog() {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title: 'Remove exercise',
            message: 'Are you sure you want to remove this exercise?',
            actions: {
                confirm: {
                    label: 'Yes',
                },
            },
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe(async (result) => {
            // If the confirm button pressed...
            if (result === 'confirmed') {
                this.deleteExercise.emit(true);
            }
        });
    }
}
