// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { take } from 'rxjs';
import { FormsService } from './forms.service';

@Injectable()
export class FormGuard implements CanActivate {
    constructor(public _formsService: FormsService, public router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        let fragment = '';
        let coachId = route.paramMap.get('coachId');
        let coachFormId = route.paramMap.get('coachFormId');
        let assignedFormId = route.paramMap.get('assignedFormId');
        let isPublic = false;

        if (!coachId || !coachFormId || !assignedFormId) {
            [fragment, coachId, coachFormId, assignedFormId] =
                state.url.split('/');
        }

        return true;
    }
}
