import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

@Component({
    selector: 'timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
    timerStarted: boolean = false;
    timerPaused: boolean = false;
    reachedZero: boolean = false;

    @Input()
    open: boolean = true;

    @Output()
    closed: EventEmitter<boolean> = new EventEmitter<boolean>();

    timerInterval: any;

    @Input()
    startValue: number = 60;

    timerValue: number;

    displayTimer: boolean = false;

    constructor() {}

    ngOnInit(): void {
        this.timerValue = this.startValue;
    }

    ngOnDestroy(): void {
        clearInterval(this.timerInterval);
    }

    startTimer(): void {
        this.timerStarted = true;
        this.reachedZero = false;
        this.timerInterval = setInterval(() => {
            if (!this.timerPaused) {
                if (this.timerValue > 0) {
                    this.timerValue--;
                }
                if (this.timerValue === 0) {
                    this.reachedZero = true;
                }
            }
        }, 1000);
    }

    pauseTimer(): void {
        this.timerPaused = !this.timerPaused;
    }

    continue() {
        this.timerPaused = false;
    }

    stopTimer(): void {
        clearInterval(this.timerInterval);
        this.timerStarted = false;
        this.timerPaused = false;
        this.timerValue = this.startValue;
    }

    increaseByXSeconds(seconds: number) {
        let newValue = this.timerValue + seconds;
        this.reachedZero = false;

        if (newValue < 0) {
            newValue = 0;
        }

        this.timerValue = newValue;
    }

    resetTimer(): void {
        this.timerValue = this.startValue;
    }

    close() {
        this.open = false;
        this.closed.emit(true);
    }
}
