import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate } from '@angular/router';
import { UserStore } from 'app/core/global-stores/user.store';
import { UserType } from 'app/enums/user-type.enum';
import { Observable, map, filter } from 'rxjs';

@Injectable()
export class IsUserACoachGuard implements CanActivate {
    constructor(private _router: Router, private userStore: UserStore) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.userStore.getUserType.pipe(
            filter(userType => !!userType),
            map((userType) => {
                if (userType === UserType.COACH) {
                    return true;
                } else {
                    this._router.navigate(['/home']);
                    return false;
                }
            })
        );
    }
}
