import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, filter, tap } from 'rxjs';
import {
    clientUserNavigationTree,
    coachUserNavigationTree,
} from './navigation-structure';
import { UserType } from 'app/enums/user-type.enum';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { UserStore } from '../global-stores/user.store';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _navigation: ReplaySubject<FuseNavigationItem[]> =
        new ReplaySubject<FuseNavigationItem[]>(1);
    private _coachNavigationStructure = coachUserNavigationTree;
    private _clientNavigationStructure = clientUserNavigationTree;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private userStore: UserStore
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<FuseNavigationItem[]> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<FuseNavigationItem[] | any> {
        return this.userStore.state$.pipe(
            filter(
                (state) =>
                    state.isAdminCoach !== null ||
                    state?.user?.type === UserType.CLIENT
            ),
            tap((state) => {
                if (state.user.type === UserType.COACH) {
                    if (state.isAdminCoach) {
                        this._navigation.next(this._coachNavigationStructure);
                    } else {
                        this._navigation.next(
                            this._coachNavigationStructure?.filter(
                                (_) => !_?.meta?.isAdmin
                            )
                        );
                    }
                }
                if (state.user.type === UserType.CLIENT) {
                    this._navigation.next(this._clientNavigationStructure);
                }
            })
        );
    }
}
