import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { Exercise } from 'app/models/exercise';
import { Observable } from 'rxjs';
import { combineLatest, map, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ExerciseService extends SubscribeUntilDestroyedComponent {
    constructor(private firestore: AngularFirestore) {
        super();
    }

    getExercisesByCoach(coachId: string): Observable<Exercise[]> {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('exercises')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Exercise>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<Exercise>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return {
                                id,
                                ...data,
                                exerciseId: id,
                                editable: true,
                                custom: true,
                                value: id,
                            } as Exercise;
                        }
                    );
                })
            );
    }

    getPreDefinedExercises(): Observable<Exercise[]> {
        return this.firestore
            .collection('exercises')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Exercise>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<Exercise>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return {
                                id,
                                ...data,
                                exerciseId: id,
                                name: data.exercise,
                                editable: false,
                                value: id,
                            } as Exercise;
                        }
                    );
                })
            );
    }

    combineArraysByExerciseId(
        defaultEx: Exercise[],
        coaches: Exercise[]
    ): Exercise[] {
        let combinedExercises: Exercise[] = [...defaultEx];

        // Loop through coaches exercises and overide CK ones.
        coaches.forEach((coachEx) => {
            // Check if the exercise id already exists in default
            let index = combinedExercises.findIndex(
                (exercise) => exercise.id === coachEx.id
            );
            if (index > -1) {
                combinedExercises[index] = coachEx;
            } else {
                combinedExercises.push(coachEx);
            }
        });

        return combinedExercises.sort((a, b) => a.name.localeCompare(b.name));
    }

    addExercise(coachId: string, exercise: Exercise) {
        exercise.created = new Date();
        exercise.lastUpdated = new Date();

        const docRef = this.firestore
            .collection('users')
            .doc(coachId)
            .collection('exercises')
            .doc();

        exercise.exerciseId = docRef.ref.id;
        exercise.id = docRef.ref.id;

        return docRef.ref.set(exercise, { merge: true });
    }

    updateExercise(coachId: string, exercise: Exercise) {
        exercise.lastUpdated = new Date();

        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('exercises')
            .doc(exercise.id)
            .set(exercise, { merge: true });
    }

    deleteExercise(coachId: string, exerciseId: string) {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('exercises')
            .doc(exerciseId)
            .delete();
    }
}
