import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { tap, take, combineLatest } from 'rxjs';
import { AddServiceComponent } from './add-service/add-service.component';
import { CoachService } from './coach-service.model';
import { CoachServicesService } from './coachServices.service';
import { UserStore } from 'app/core/global-stores/user.store';
import { UsersService } from 'app/core/services/users/users.service';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
})
export class ServicesComponent
    extends SubscribeUntilDestroyedComponent
    implements OnInit
{
    yearlyBilling: boolean = false;

    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    drawerMode: 'side' | 'over';

    orgId: string;
    isAdmin: boolean = false;

    loading: boolean = false;

    services: CoachService[] = [];

    stripeEnabled: boolean;

    constructor(
        private _matDialog: MatDialog,
        private _snackbarService: SnackbarService,
        private userStore: UserStore,
        private _coachServices: CoachServicesService,
        private _userService: UsersService
    ) {
        super();
    }

    ngOnInit(): void {
        this.loading = true;

        this.subscribe(
            combineLatest(
                this.userStore.getUser,
                this.userStore.getCoachingBusinessId,
                this.userStore.getIsAdminCoach
            ).pipe(
                tap(([user, orgId, isAdmin]) => {
                    this.orgId = orgId;
                    this.isAdmin = isAdmin;

                    //Load org profile if its not the main user.
                    if (orgId !== user?.uid) {
                        this.subscribe(
                            this._userService.getUser(orgId).pipe(
                                take(1),
                                tap((orgProfile) => {
                                    this.stripeEnabled =
                                        orgProfile?.paymentOptions?.stripe
                                            ?.connected ?? false;
                                })
                            )
                        );
                    } else {
                        this.stripeEnabled =
                            user?.paymentOptions?.stripe?.connected ?? false;
                    }
                })
            )
        );

        this.subscribe(
            this._coachServices.getCoachServies(this.orgId).pipe(
                tap((services) => {
                    this.services = services;

                    for (const service of services) {
                        this.subscribe(
                            this._coachServices
                                .getPrices(this.orgId, service.id)
                                .pipe(
                                    tap((prices) => {
                                        service.prices =
                                            prices?.filter((_) => _.amount) ??
                                            null;
                                    })
                                )
                        );
                    }

                    this.services = services;
                    this.loading = false;
                })
            )
        );
    }

    async getStripeAccountStatus() {
        try {
            const result = await this._coachServices.getStripeAccountStatus();
            console.log(JSON.stringify(result));
        } catch (err) {
            console.error('Error getting account status', err);
        }
    }

    openImportModal() {
        // // Open the dialog
        // const dialogRef = this._matDialog.open(ImportProductsComponent);
        // dialogRef.afterClosed().subscribe((result) => {
        //     console.log('Compose dialog was closed!');
        // });
    }

    addService() {
        const dialogRef = this._matDialog.open(AddServiceComponent);

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.success) {
                this._snackbarService.success('Service created successfully');
            }
        });
    }
    edit(service: CoachService) {
        const dialogRef = this._matDialog.open(AddServiceComponent, {
            data: { orgId: this.orgId, service: service },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.success) {
                this._snackbarService.success('Service updated successfully');
            }
        });
    }
}
