/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'main',
        title: 'Dashboard',
        link: '/dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'group',
        icon: 'heroicons_outline:user-group',
        link: '/clients',
    },
    {
        id: 'checkins',
        title: 'Check-ins',
        type: 'group',
        icon: 'heroicons_outline:clipboard-list',
        link: '/checkins',
    },
    {
        id: 'forms',

        title: 'Forms',
        // link: '/forms',
        type: 'group',
        icon: 'heroicons_outline:document-text',
        children: [
            {
                id: 'forms.my',
                title: 'My Forms',
                type: 'collapsable',
                icon: 'heroicons_outline:pencil-alt',
                children: [
                    {
                        id: 'forms.my.view',
                        title: 'View',
                        type: 'basic',
                        icon: 'heroicons_outline:clipboard-list',
                        link: '/forms/view',
                    },
                    {
                        id: 'forms.my.create',
                        title: 'Create',
                        type: 'basic',
                        icon: 'heroicons_outline:document-add',
                        link: '/forms/editor',
                    },
                ],
            },
            {
                id: 'forms.submissions',
                title: 'Submissions',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/forms/submissions',
            },
        ],
    },
    {
        id: 'recipes',
        title: 'Recipes',
        type: 'group',
        icon: 'mat_outline:blender',
        children: [],
    },
    {
        id: 'plans',
        title: 'Training Plans',
        type: 'basic',
        icon: 'mat_outline:directions_run',
        link: '/plans/training',
    },
    {
        id: 'services',
        title: 'Services',
        type: 'group',
        link: '/services',
        icon: 'heroicons_outline:shopping-cart',
    },

    {
        id: 'account',
        title: 'Account',
        type: 'group',
        link: '/settings',
        icon: 'heroicons_outline:user-circle',
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'main',
        title: 'Dashboard',
        type: 'basic',
        link: '/dashboard',
        icon: 'mat_outline:dashboard',
        children: [],
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/clients',
    },
    {
        id: 'checkins',
        title: 'Check-ins',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/checkins',
    },
    {
        id: 'todo',
        title: 'Task Boards',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/todo',
    },
    {
        id: 'plans',
        title: 'Training Plans',
        type: 'basic',
        icon: 'mat_outline:directions_run',
        link: '/plans/training',
    },
    {
        id: 'nutrition',
        title: 'Nutrition',
        type: 'basic',
        icon: 'mat_outline:directions_run',
        link: '/plans/nutrition',
    },
    {
        id: 'forms',
        title: 'Forms',
        type: 'aside',
        icon: 'heroicons_outline:document-text',
        children: [],
    },
    {
        id: 'services',
        title: 'Services',
        type: 'basic',
        link: '/services',
        icon: 'heroicons_outline:shopping-cart',
    },
    {
        id: 'account',
        title: 'Account',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/settings',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/clients',
        // adminOnly: true,
    },
    {
        id: 'todo',
        title: 'Task Boards',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/todo',
    },
    {
        id: 'checkins',
        title: 'Check-ins',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/check-ins',
        badge: {
            title: 'Coming Soon',
            classes: 'px-2 bg-yellow-300 text-black rounded-full',
        },
    },
    {
        id: 'recipes',
        title: 'Recipes',
        type: 'basic',
        icon: 'mat_outline:blender',
        link: '/recipes',
        badge: {
            title: 'Coming Soon',
            classes: 'px-2 bg-yellow-300 text-black rounded-full',
        },
    },
    {
        id: 'plans',
        title: 'Training Plans',
        type: 'basic',
        icon: 'mat_outline:directions_run',
        link: '/plans',
        badge: {
            title: 'Coming Soon',
            classes: 'px-2 bg-yellow-300 text-black rounded-full',
        },
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/clients',
        // adminOnly: true,
    },
    {
        id: 'todo',
        title: 'Task Boards',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/todo',
    },
    {
        id: 'checkins',
        title: 'Check-ins',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/check-ins',
        badge: {
            title: 'Coming Soon',
            classes: 'px-2 bg-yellow-300 text-black rounded-full',
        },
    },
    {
        id: 'recipes',
        title: 'Recipes',
        type: 'basic',
        icon: 'iconsmind:chef',
        link: '/recipes',
        badge: {
            title: 'Coming Soon',
            classes: 'px-2 bg-yellow-300 text-black rounded-full',
        },
    },
    {
        id: 'plans',
        title: 'Training Plans',
        type: 'basic',
        icon: 'iconsmind:running',
        link: '/plans',
        badge: {
            title: 'Coming Soon',
            classes: 'px-2 bg-yellow-300 text-black rounded-full',
        },
    },
];
