import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Client } from 'app/models/client';
import { Coach } from 'app/models/coach';
import { Calendly } from 'app/models/user';
import { catchError, map, pipe, retry, switchMap, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UsersService } from '../services/users/users.service';

export interface UserStoreState {
    loading: boolean;
    user: Coach | Client;
    isProfileComplete: boolean;
    isTermsOfUseAccepted: boolean;
    calendlyCredentials: Calendly;
    coachingBusinessId: string;
    isAdminCoach: boolean;
}

@Injectable({ providedIn: 'root' })
export class UserStore extends ComponentStore<UserStoreState> {
    readonly getLoading = this.select((state) => state.loading);
    readonly getUser = this.select((state) => state.user);
    readonly getCoachUser = this.select((state) => state.user as Coach);
    readonly getClientUser = this.select((state) => state.user as Client);
    readonly getUserType = this.select((state) => state.user?.type);
    readonly getIsProfileComplete = this.select(
        (state) => state.isProfileComplete
    );
    readonly getIsTermsOfUseAccepted = this.select(
        (state) => state.isTermsOfUseAccepted
    );
    readonly getCalendlyCredentials = this.select(
        (state) => state.calendlyCredentials
    );
    readonly getCoachingBusinessId = this.select(
        (state) => state.coachingBusinessId
    );

    readonly getIsAdminCoach = this.select((state) => state.isAdminCoach);

    readonly getUserInitials = this.select((state) => state.user).pipe(
        map((user) => {
            if (user && user.profile) {
                const { profile } = user.profile as Coach;
                return (profile.firstName[0] + profile.lastName[0]) as string;
            } else if (user) {
                return (user.firstName[0] + user.lastName[0]) as string;
            }
            return undefined;
        })
    );

    readonly setLoading = this.updater((state, loading: boolean) => ({
        ...state,
        loading,
    }));
    readonly setUser = this.updater((state, user: Coach | Client) => ({
        ...state,
        user,
    }));
    readonly setIsProfileComplete = this.updater(
        (state, isProfileComplete: boolean) => ({ ...state, isProfileComplete })
    );
    readonly setIsAdminCoach = this.updater((state, isAdminCoach: boolean) => ({
        ...state,
        isAdminCoach,
    }));
    readonly setCoachingBusinessId = this.updater(
        (state, coachingBusinessId: string) => ({
            ...state,
            coachingBusinessId,
        })
    );
    readonly setIsTermsOfUseAccepted = this.updater(
        (state, isTermsOfUseAccepted: boolean) => ({
            ...state,
            isTermsOfUseAccepted,
        })
    );
    readonly setCalendlyCredentials = this.updater(
        (state, calendlyCredentials: Calendly) => ({
            ...state,
            calendlyCredentials,
        })
    );

    readonly loadUser = this.effect<void>(
        pipe(
            tap(() => this.setLoading(true)),
            switchMap(() => {
                const credentials = this.authService.checkCredentials();
                if (!credentials) {
                    return [];
                }
                return this.usersService.getUser(credentials.uid);
            }),
            tap((user) => {
                this.setUser(user);
                this.setIsProfileComplete(user?.profileComplete);
                this.setIsTermsOfUseAccepted(user?.agreedTerms);
                this.setCalendlyCredentials(user?.calendly);
                this.setLoading(false);

                //Default to member of uuid if present otherwise its the default user and will be their uid.
                this.setCoachingBusinessId(user?.memberOf ?? user?.uid);
                this.setIsAdminCoach(
                    user?.memberOf
                        ? user?.role?.toLowerCase() === 'admin'
                        : true
                );
            }),
            catchError((err) => {
                console.error(err);
                return err;
            }),
            retry(2)
        )
    );

    constructor(
        private authService: AuthService,
        private usersService: UsersService
    ) {
        super({
            loading: false,
            user: null,
            isProfileComplete: false,
            isTermsOfUseAccepted: false,
            calendlyCredentials: undefined,
            coachingBusinessId: null,
            isAdminCoach: null,
        });
        this.loadUser();
    }
}
