import { Injectable } from '@angular/core';

// Firebase
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

// RxJS
import {
    catchError,
    Observable,
    of,
    switchMap,
    throwError,
    from,
    BehaviorSubject,
    map,
} from 'rxjs';
import { tap } from 'rxjs';

// Models and Services
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { ClientService } from 'app/modules/clients/client.service';
import { UserType } from 'app/enums/user-type.enum';
import { User } from 'app/models/user';

@Injectable()
export class AuthService extends SubscribeUntilDestroyedComponent {
    credentials: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    currentUser = new BehaviorSubject<User>(null);

    /**
     * Constructor
     */
    constructor(
        private _firebaseAuth: AngularFireAuth,
        private _clientService: ClientService
    ) {
        super();
        this.subscribe(
            this._firebaseAuth.authState.pipe(
                tap((user) => {
                    if (user) {
                        // Load the profile for the user
                        this._clientService
                            .getUser(user.uid)
                            .subscribe((profile) => {
                                this.currentUser.next(profile);
                            });
                    } else {
                        this.currentUser.next(null);
                    }
                })
            )
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get credentials$(): any {
        return this.credentials$;
    }

    set credentials$(credentials: any) {
        this.credentials.next(credentials);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return from(this._firebaseAuth.sendPasswordResetEmail(email)).pipe(
            tap((_) => of(true))
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in

        // try setting persistence to session
        try {
            const session = async () =>
                await this._firebaseAuth.setPersistence('session');
        } catch (error) {
            console.warn(error);
        }

        return from(
            this._firebaseAuth.signInWithEmailAndPassword(
                credentials.email,
                credentials.password
            )
        ).pipe(
            catchError(() => {
                throwError('Wrong email or password.');
                return of(false);
            }),
            switchMap((response: any) => {
                if (!response) {
                    return of(false);
                } // if user does not exist, return false

                this.checkCredentials();

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign out
     */
    async signOut(): Promise<any> {
        // Set the authenticated flag to false
        return await this._firebaseAuth.signOut().finally(() => {
            this.credentials$ = null;
        });
    }

    sendVerificationEmail() {
        firebase.auth().currentUser.sendEmailVerification();
        return;
    }

    resetPassword(email: string): Promise<any> {
        return this._firebaseAuth
            .sendPasswordResetEmail(email)
            .then((resp) => resp)
            .catch((error) => error);
    }

    handleResetPassword(actionCode: string): Promise<any> {
        return this._firebaseAuth
            .verifyPasswordResetCode(actionCode)
            .then((email) => {
                const accountEmail = email;
                return email;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    confirmPasswordReset(
        actionCode: string,
        newPassword: string
    ): Promise<any> {
        return this._firebaseAuth
            .confirmPasswordReset(actionCode, newPassword)
            .then((resp) => resp)
            .catch((error) => {
                throw new Error(error);
            });
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        return this.credentials$.value ? of(true) : of(false);
    }

    checkCredentials(): any {
        const credentials: Object = firebase.auth().currentUser?.toJSON();
        this.credentials$ = credentials;
        return credentials;
    }

    getCredentialsAsObservable(): Observable<any> {
        return of(firebase.auth().currentUser?.toJSON());
    }
}
