<nav
    mat-tab-nav-bar
    class="header-bg-white bg-white w-full shadow-2xl border-t"
>
    <a
        *ngFor="let item of navigationItems"
        mat-tab-link
        [routerLink]="item.link"
        [active]="activeLink === item.link"
        class="w-full"
    >
        <mat-icon
            [svgIcon]="item.icon"
            class="icon-size-5"
            [ngClass]="{ 'text-primary': activeLink === item.link }"
        ></mat-icon
    ></a>
</nav>
