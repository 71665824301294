import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickActionsComponent {
  @Input() icon: string;
}
