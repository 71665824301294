import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'warning-snack',
    templateUrl: './warning-snack.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningSnackComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: { title: string; message: string }
    ) {}
}
