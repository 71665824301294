import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'exercise-log-empty-state',
    templateUrl: './exercise-log-empty-state.component.html',
    styleUrls: ['./exercise-log-empty-state.component.scss'],
})
export class ExerciseLogEmptyStateComponent implements OnInit {
    @Input()
    date: Date;

    @Input()
    isToday: boolean = false;

    @Output()
    addExercise: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    add() {
        this.addExercise.emit(true);
    }
}
