import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    BehaviorSubject,
    map,
    Observable,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import {
    Chat,
    ChatMessage,
} from 'app/layout/common/quick-chat/quick-chat.types';
import {
    AngularFirestore,
    DocumentChangeAction,
    DocumentReference,
} from '@angular/fire/compat/firestore';

import { arrayUnion, arrayRemove, increment } from '@angular/fire/firestore';
import { UserType } from 'app/enums/user-type.enum';
import { User } from 'app/models/user';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
    private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>(null);
    _openChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    unreadChats: BehaviorSubject<number> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private firestore: AngularFirestore
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for chat
     */
    get chat$(): Observable<Chat> {
        return this._chat.asObservable();
    }

    /**
     * Getter for chat
     */
    get chats$(): Observable<Chat[]> {
        return this._chats.asObservable();
    }

    get openChat$(): Observable<boolean> {
        return this._openChat.asObservable();
    }

    get getUnreadCount$(): Observable<number> {
        return this.unreadChats.asObservable();
    }

    toggleChat() {
        this._openChat.next(true);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chats
     */
    getChats(): Observable<any> {
        return this._httpClient.get<Chat[]>('api/apps/chat/chats').pipe(
            tap((response: Chat[]) => {
                this._chats.next(response);
            })
        );
    }

    getChatByClientIdAndCoachId(coachId: string, clientId: string) {
        return this.firestore
            .collection('chats', (ref) =>
                ref
                    .where('clientId', '==', clientId)
                    .where('coachId', '==', coachId)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Chat>[]) => {
                    return actions.map((action: DocumentChangeAction<Chat>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        const lastMessageAt: Date =
                            data.lastMessageAt?.toDate();
                        return { id, ...data, lastMessageAt } as Chat;
                    });
                })
            );
    }

    getChatByClientId(clientId: string) {
        return this.firestore
            .collection('chats', (ref) => ref.where('clientId', '==', clientId))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Chat>[]) => {
                    return actions.map((action: DocumentChangeAction<Chat>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        const lastMessageAt: Date =
                            data.lastMessageAt?.toDate();
                        const unreadCount = data.clientUnreadCount;

                        return {
                            id,
                            ...data,
                            lastMessageAt,
                            unreadCount,
                        } as Chat;
                    });
                })
            );
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(id: string): Observable<any> {
        return this._httpClient
            .get<Chat>('api/apps/chat/chat', { params: { id } })
            .pipe(
                map((chat) => {
                    // Update the chat
                    this._chat.next(chat);

                    // Return the chat
                    return chat;
                }),
                switchMap((chat) => {
                    if (!chat) {
                        return throwError(
                            'Could not found chat with id of ' + id + '!'
                        );
                    }

                    return of(chat);
                })
            );
    }

    getCoachChats(coachId: string) {
        return this.firestore
            .collection('chats', (ref) => ref.where('coachId', '==', coachId))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Chat>[]) => {
                    return actions.map((action: DocumentChangeAction<Chat>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        const lastMessageAt: Date =
                            data.lastMessageAt?.toDate();
                        const unreadCount = data.coachUnreadCount;

                        return {
                            id,
                            ...data,
                            lastMessageAt,
                            unreadCount,
                        } as Chat;
                    });
                })
            );
    }

    getUnreadMessages(userId: string, type: UserType) {
        return this.firestore
            .collection('chats', (ref) =>
                ref
                    .where(
                        type === UserType.CLIENT ? 'clientId' : 'coachId',
                        '==',
                        userId
                    )
                    .where(
                        type === UserType.CLIENT
                            ? 'clientUnreadCount'
                            : 'coachUnreadCount',
                        '>',
                        0
                    )
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Chat>[]) => {
                    return actions.map((action: DocumentChangeAction<Chat>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        const lastMessageAt: Date =
                            type === UserType.CLIENT
                                ? data.clientUnreadLastMessageAt?.toDate()
                                : data.coachUnreadLastMessageAt?.toDate();
                        const lastMessage: string =
                            type === UserType.CLIENT
                                ? data.clientUnreadLastMessage
                                : data.coachUnreadLastMessage;
                        const unreadCount =
                            type === UserType.CLIENT
                                ? data.clientUnreadCount
                                : data.coachUnreadCount;
                        return {
                            id,
                            ...data,
                            lastMessageAt,
                            lastMessage,
                            unreadCount,
                        } as Chat;
                    });
                })
            );
    }

    getClientChats(clientId: string) {
        return this.firestore
            .collection('chats', (ref) => ref.where('clientId', '==', clientId))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Chat>[]) => {
                    return actions.map((action: DocumentChangeAction<Chat>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        const lastMessageAt: Date =
                            data.lastMessageAt?.toDate();
                        return { id, ...data, lastMessageAt } as Chat;
                    });
                })
            );
    }

    getChatMessages(chatId: string, currentUserId: string) {
        return this.firestore
            .collection('chats')
            .doc(chatId)
            .collection('messages', (ref) => ref.orderBy('createdAt', 'asc'))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<ChatMessage>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<ChatMessage>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            const isMine: boolean =
                                data?.userId === currentUserId;
                            const createdAt: Date = data?.createdAt?.toDate();
                            return {
                                id,
                                ...data,
                                isMine,
                                createdAt,
                            } as ChatMessage;
                        }
                    );
                })
            );
    }

    getChat(chatId: string) {
        return this.firestore
            .collection('chats')
            .doc<Chat>(chatId)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data = a?.payload?.data();
                        const id = a?.payload?.id;
                        const lastMessageAt: Date =
                            data.lastMessageAt?.toDate();
                        return { id, ...data, lastMessageAt } as Chat;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    async sendMessage(
        chatId: string,
        message: ChatMessage,
        userType: UserType
    ) {
        //Update last sent on main chat too.
        const batch = this.firestore.firestore.batch();

        const fieldsToUpdate =
            userType === UserType.CLIENT ? 'coachUnread' : 'clientUnread';

        const fieldToIncrement = `${fieldsToUpdate}Count`;

        const lastMessageByUser = `${fieldsToUpdate}LastMessage`;
        const lastMessageAtByUser = `${fieldsToUpdate}LastMessageAt`;

        const chatRef = this.firestore.collection('chats').doc(chatId).ref;
        const messageRef = this.firestore
            .collection('chats')
            .doc(chatId)
            .collection('messages')
            .doc().ref;

        //Update latest message and increment unread count by 1.
        batch.set(
            chatRef,
            {
                lastMessageAt: message.createdAt,
                lastMessage: message.value,
                [lastMessageByUser]: message.value,
                [lastMessageAtByUser]: message.createdAt,
                [fieldToIncrement]: increment(1),
            },
            { merge: true }
        );
        batch.set(messageRef, message);

        return await batch.commit();
    }

    async createNewChat(chat: Chat) {
        return await this.firestore.collection('chats').add(chat);
    }

    deleteChat(chatId: string) {
        return this.firestore.collection('chats').doc(chatId).delete();
    }

    async clearMessages(chatId: string) {
        const batch = this.firestore.firestore.batch();

        try {
            this.firestore
                .collection('chats')
                .doc(chatId)
                .collection('messages')
                .get()
                .toPromise()
                .then(async (res) => {
                    let count = 0;
                    let total = res?.docs?.length;

                    for (let message of res?.docs) {
                        batch.delete(message?.ref);

                        count++;

                        if (count == 500 || count == total) {
                            if (count == 500) {
                                //Its completed 500 records.
                                total = res?.docs?.length - 500;
                            }
                            count = 0;
                            await batch.commit();
                        }
                    }
                });
        } catch (err) {
            throw err;
        }
    }

    muteChat(chatId: string, mute: boolean, userId: string) {
        return this.firestore
            .collection('chats')
            .doc(chatId)
            .set(
                {
                    muted: mute,
                    mutedBy: mute ? arrayUnion(userId) : arrayRemove(userId),
                },
                { merge: true }
            );
    }

    readMessage(chatId: string, userType: UserType) {
        const field =
            userType === UserType.CLIENT
                ? 'clientUnreadCount'
                : 'coachUnreadCount';
        return this.firestore
            .collection('chats')
            .doc(chatId)
            .set(
                {
                    [field]: 0,
                },
                { merge: true }
            );
    }
}
