import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { clientToolbarNavigationItems } from 'app/core/navigation/navigation-structure';

@Component({
    selector: 'mobile-navigation',
    templateUrl: './mobile-navigation.component.html',
    styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent {
    navigationItems = clientToolbarNavigationItems;
    constructor(private router: Router) {}

    get activeLink(): string {
        return this.router.url;
    }
}
