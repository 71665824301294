import { Pipe, PipeTransform } from '@angular/core';
import { PaymentFrequency } from 'app/enums/payment-frequency.enum';

@Pipe({ name: 'paymentFrequency' })
export class PaymentFrequencyPipe implements PipeTransform {
    transform(status: number): string {
        switch (status) {
            case PaymentFrequency.ONE_OFF:
                return 'one off';

            case PaymentFrequency.DAILY:
                return 'daily';

            case PaymentFrequency.MONTHLY:
                return 'monthly';

            case PaymentFrequency.WEEKLY:
                return 'weekly';

            default:
                return 'Unknown';
        }
    }
}
