<div
    class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6 overflow-y-auto"
    *ngIf="form"
>
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
    >
        <div class="text-lg font-medium">
            Which price would you like to generate a payment link for?
        </div>
        <button mat-icon-button (click)="matDialogRef.close()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"
            ></mat-icon>
        </button>
    </div>

    <form
        [formGroup]="form"
        class="flex flex-col px-8 pt-10 bg-card shadow rounded overflow-hidden"
    >
        <!-- Prices -->
        <div class="flex">
            <div class="flex-auto w-full">
                <mat-radio-group
                    class="flex flex-col"
                    [color]="'primary'"
                    [formControlName]="'price'"
                    [value]="'just-good'"
                >
                    <mat-radio-button
                        *ngFor="let price of prices"
                        class="mb-2"
                        [value]="price"
                    >
                        <b>£{{ price.amount }}</b> billed
                        {{ price.paymentFrequency | paymentFrequency }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="w-full">
            <div
                class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5 bg-gray-50 dark:bg-gray-700"
            >
                <button type="button" mat-button (click)="matDialogRef.close()">
                    Cancel
                </button>
                <button
                    class="px-8"
                    mat-flat-button
                    [disabled]="form.invalid"
                    [color]="'primary'"
                    (click)="select()"
                    type="button"
                >
                    <span>Generate Link</span>
                </button>
            </div>
        </div>
    </form>
</div>
