import { UserType } from 'app/enums/user-type.enum';
import {
    ChatContact,
    Chat,
} from 'app/layout/common/quick-chat/quick-chat.types';
import { NewClient } from 'app/models/newClient';

export function mapCoachToChatContact(coach: any): ChatContact {
    return {
        id: coach.id,
        name: `${coach?.profile?.firstName} ${coach?.profile?.lastName}`,
        type: UserType.COACH,
        avatar: null,
    };
}

export function mapClientToChatContact(client: NewClient): ChatContact {
    return {
        id: client.id,
        name: `${client.firstName} ${client.lastName}`,
        type: UserType.CLIENT,
        avatar: client.avatar,
    };
}

export function mapChats(
    chats: Chat[],
    clients: NewClient[],
    currentUserId: string
) {
    let allChats: Chat[] = chats?.map((chat: Chat) => {
        const client = clients?.find((_) => _.id === chat.clientId);
        if (client) {
            chat.contact = mapClientToChatContact(client);
        }

        //Set mute.
        chat.muted = chat?.mutedBy?.includes(currentUserId);

        return chat;
    });

    return allChats;
}
