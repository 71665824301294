import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserType } from 'app/enums/user-type.enum';
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { tap } from 'rxjs';
import { BrandService } from 'app/core/services/coach/brand.service';
import { UserStore } from 'app/core/global-stores/user.store';
import { User } from 'app/models/user';

@Component({
    selector: 'compact-layout',
    templateUrl: './compact.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CompactLayoutComponent
    extends SubscribeUntilDestroyedComponent
    implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    userType: UserType;
    isUserCoach: boolean;
    userId: string;
    coachLogoElseDefault: string = '/assets/images/logos/CK_1.svg';
    orgId: string;
    user: User;
    loading: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _userStore: UserStore,
        private _brandService: BrandService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loading = true;
        this.subscribe(
            this._userStore.state$.pipe(
                tap((state) => {
                    const user = state.user;
                    this.user = user;
                    this.orgId = state.coachingBusinessId;
                    this.userType = user.type;
                    this.userId = user?.uid;
                    this.isUserCoach = UserType.COACH === user.type;
                }),
                switchMap((user) => {
                    if (this.userType === UserType.COACH) {
                        return this._brandService
                            .getBrandCollection(this.orgId)
                            .pipe(
                                switchMap((brand) => {
                                    return this._brandService
                                        .getBrandDocument(
                                            this.orgId,
                                            brand?.length > 0
                                                ? brand[0].id
                                                : 'brand'
                                        )
                                        .pipe(
                                            tap((brand) => {
                                                if (brand?.logo) {
                                                    this.coachLogoElseDefault =
                                                        brand.logo;
                                                }
                                                this.loading = false;
                                            })
                                        );
                                })
                            );
                    } else {
                        return this._brandService
                            .getBrandCollection(this.user.coachId)
                            .pipe(
                                switchMap((brand) => {
                                    if (brand.length) {
                                        return this._brandService
                                            .getBrandDocument(
                                                this.user.coachId,
                                                brand[0].id
                                            )
                                            .pipe(
                                                tap((brand) => {
                                                    if (brand.logo) {
                                                        this.coachLogoElseDefault =
                                                            brand.logo;
                                                    }
                                                    this.loading = false;
                                                })
                                            );
                                    }
                                })
                            );
                    }
                })
            )
        );

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: FuseNavigationItem[]) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
