<!-- Card 1 -->
<fuse-card class="flex-col max-w-sm md:max-w-none p-6 sm:py-12 sm:px-10 mt-4">
    <div class="flex items-center justify-between">
        <div class="text-4xl font-bold tracking-tight leading-tight">
            {{ service.name }}
        </div>
        <div class="-mr-3" *ngIf="isAdmin">
            <button mat-icon-button [matMenuTriggerFor]="listCard01Menu">
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:dots-vertical'"
                ></mat-icon>
            </button>
            <mat-menu #listCard01Menu="matMenu">
                <button mat-menu-item (click)="addPrice()">Add Price</button>

                <button mat-menu-item (click)="edit()">Edit</button>

                <button mat-menu-item (click)="delete()">Delete</button>
            </mat-menu>
        </div>
    </div>

    <div class="mt-2 text-lg font-medium tracking-tight text-secondary">
        {{ service.duration.amount }} {{ service.duration.type | durationPipe }}
    </div>
    <div class="w-8 h-1 my-5 rounded bg-accent"></div>

    <!-- Prices -->
    <ng-container *ngIf="service.prices?.length > 0; else noPrices">
        <div class="flex items-baseline whitespace-nowrap">
            <div class="mr-2 text-2xl">GBP</div>
            <div class="text-6xl font-semibold leading-tight tracking-tight">
                £{{ service.prices[0].amount }}
            </div>
        </div>
        <!-- Frequency -->
        <div class="flex flex-col mt-2 text-secondary">
            <div>
                billed
                {{ service.prices[0].paymentFrequency | paymentFrequency }}
            </div>
        </div>
    </ng-container>
    <ng-template #noPrices>
        <div class="mt-2 text-lg font-medium tracking-tight text-secondary">
            No prices available for this service.
        </div>
    </ng-template>

    <ng-container *ngIf="service.prices?.length > 1">
        <!-- All Prices -->
        <div class="flex flex-col mt-12">
            <div class="font-semibold">
                {{ service.prices?.length }} total prices available:
            </div>
            <div class="mt-4 space-y-2">
                <div class="flex" *ngFor="let price of service.prices">
                    <ng-container *ngIf="price?.amount">
                        <mat-icon
                            class="icon-size-5 text-green-600"
                            [svgIcon]="'heroicons_solid:check'"
                        ></mat-icon>
                        <div class="ml-2 leading-5">
                            <b>£{{ price.amount }}</b> billed
                            {{ price.paymentFrequency | paymentFrequency }}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="paymentLink; else noLink">
        <div class="flex mt-12">
            <mat-form-field
                class="flex-auto fuse-mat-bold fuse-mat-emphasized-affix"
            >
                <input [value]="paymentLink" readonly [disabled]="true" />

                <input class="text-right" matInput />
                <span matPrefix>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:link'"
                    ></mat-icon>
                </span>
                <span
                    matSuffix
                    [matTooltip]="'Copy'"
                    class="cursor-pointer"
                    (click)="copyToClipboard(paymentLink)"
                >
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:clipboard'"
                    ></mat-icon>
                </span>
            </mat-form-field>
        </div>
    </ng-container>

    <ng-template #noLink>
        <!-- Generate stripe payment link -->

        <ng-container
            *ngIf="
                service?.stripe?.id && stripeEnabled;
                else productNotInStripe
            "
        >
            <button
                *ngIf="service.prices?.length > 0"
                class="fuse-mat-button-large mt-10"
                mat-stroked-button
                [color]="'primary'"
                [disabled]="generating"
                (click)="generatePaymentLink()"
            >
                <span *ngIf="!generating"> Generate Payment Link</span>
                <mat-progress-spinner
                    [diameter]="24"
                    [mode]="'indeterminate'"
                    *ngIf="generating"
                >
                </mat-progress-spinner>
            </button>
        </ng-container>
        <ng-template #productNotInStripe>
            <ng-container *ngIf="stripeEnabled">
                <!-- Allow customer to sync product with stripe -->
                <button
                    class="fuse-mat-button-large mt-10"
                    mat-flat-button
                    [color]="'primary'"
                    [disabled]="addingToStripe"
                    (click)="addToStripe()"
                >
                    <span *ngIf="!addingToStripe"> Add to Stripe</span>
                    <mat-progress-spinner
                        [diameter]="24"
                        [mode]="'indeterminate'"
                        *ngIf="addingToStripe"
                    >
                    </mat-progress-spinner>
                </button>
            </ng-container>
        </ng-template>
    </ng-template>
</fuse-card>
