import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { MatImportsModule } from './shared/mat-imports.module';
import { SnackbarModule } from './shared/snackbar/snackbar.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { REGION } from '@angular/fire/compat/functions';
import { RecaptchaModule } from 'ng-recaptcha';

import { FormGuard } from './core/services/forms/form-guard.service';

// EMULATORS
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { ImportProductsComponent } from './modules/stripe/import-products/import-products.component';
import { IsUserACoachGuard } from './core/auth/guards/is-user-a-coach.guard';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';

import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

import { NgxClarityModule } from 'ngx-clarity';
import { IsUserAClientGuard } from './core/auth/guards/is-user-a-client.guard';
import { WorkoutCompleteComponent } from './modules/cients/client-profile/exercise-log/workout-complete/workout-complete.component';

@NgModule({
    declarations: [
        AppComponent,
        ImportProductsComponent,
        WorkoutCompleteComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireAnalyticsModule,

        MatImportsModule,
        SnackbarModule,

        HttpClientModule,
        OAuthModule.forRoot(),

        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage()),

        // MS Clarity
        NgxClarityModule.forRoot({
            enabled: environment.LOGGING.MS_CLARITY.ENABLED,
            projectId: environment.LOGGING.MS_CLARITY.PROJECT_ID,
        }),

        RecaptchaModule
    ],
    providers: [
        { provide: REGION, useValue: 'europe-west2' },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        // { provide: BUCKET, useValue: 'one-coach' },
        {
            provide: USE_FIRESTORE_EMULATOR,
            useValue: environment.useEmulators
                ? ['localhost', 8080]
                : undefined,
        },
        {
            provide: USE_FUNCTIONS_EMULATOR,
            useValue: environment.useEmulators
                ? ['localhost', 5001]
                : undefined,
        },
        {
            provide: USE_STORAGE_EMULATOR,
            useValue: environment.useEmulators
                ? ['localhost', 9199]
                : undefined,
        },
        FormGuard,
        IsUserACoachGuard,
        IsUserAClientGuard,

        // DATE PICKER FORMATTING en-GB
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD/MM/YYYY',
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
