import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'empty-state-view',
  templateUrl: './empty-state-view.component.html',
  styleUrls: ['./empty-state-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStateViewComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon: string;
}
