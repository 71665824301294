<div class="grid grid-cols-1 grid-flow-row gap-3 w-full bg-card p-4 bg-primary">
    <div class="flex flex-col">
        <div class="flex-auto grid grid-cols-5 gap-4">
            <!-- Close -->
            <div
                class="col-span-1 flex flex-col items-center justify-center"
                (click)="close()"
            >
                <button class="w-full" mat-flat-button [color]="'primary'">
                    Close
                </button>
            </div>

            <!-- Delete -->
            <div class="col-span-1 flex flex-col items-center justify-center">
                <button
                    class="w-full"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="increaseByXSeconds(-10)"
                >
                    -10s
                </button>
            </div>

            <div class="col-span-1 flex flex-col items-center justify-center">
                <!-- Add note -->

                <span class="text-white text-2xl sm:text-5xl font-semibold">{{
                    timerValue
                }}</span>
            </div>

            <div class="col-span-1 flex flex-col items-center justify-center">
                <!-- Add note -->
                <button
                    class="w-full"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="increaseByXSeconds(10)"
                >
                    +10s
                </button>
            </div>

            <div class="col-span-1 flex flex-col items-center justify-center">
                <!-- Start/ Pause -->

                <button
                    *ngIf="!timerStarted"
                    mat-icon-button
                    [matTooltip]="'Start timer'"
                    (click)="startTimer()"
                >
                    <mat-icon
                        class="text-white"
                        [svgIcon]="'heroicons_solid:play'"
                    ></mat-icon>
                </button>

                <button
                    *ngIf="timerStarted && timerPaused && !reachedZero"
                    mat-icon-button
                    [matTooltip]="'Continue'"
                    (click)="continue()"
                >
                    <mat-icon
                        class="text-white"
                        [svgIcon]="'heroicons_solid:play'"
                    ></mat-icon>
                </button>

                <button
                    *ngIf="timerValue <= 0"
                    mat-icon-button
                    [matTooltip]="'Reset'"
                    (click)="stopTimer()"
                >
                    <mat-icon
                        class="text-white"
                        [svgIcon]="'heroicons_solid:refresh'"
                    ></mat-icon>
                </button>

                <button
                    *ngIf="timerStarted && !timerPaused && !reachedZero"
                    mat-icon-button
                    [matTooltip]="'Pause timer'"
                    (click)="pauseTimer()"
                >
                    <mat-icon
                        class="text-white"
                        [svgIcon]="'heroicons_solid:pause'"
                    ></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
