import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import {
    Notification,
    PlatformNotifications,
} from 'app/layout/common/notifications/notifications.models';
import {
    AngularFirestore,
    DocumentChangeAction,
    DocumentReference,
} from '@angular/fire/compat/firestore';
import { User } from 'app/models/user';
import { NotificationType } from 'app/layout/common/notifications/notification-types.enum';
import { Client } from 'app/models/client';
import { UserType } from 'app/enums/user-type.enum';
import { Coach } from 'app/models/coach';
import { NewClient } from 'app/models/newClient';
import { user } from 'app/mock-api/common/user/data';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<
        Notification[]
    >(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private firestore: AngularFirestore
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]> {
        return this._httpClient
            .get<Notification[]>('api/common/notifications')
            .pipe(
                tap((messages) => {
                    this._notifications.next(messages);
                })
            );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(
        user: Coach | NewClient,
        id: string,
        notification: Notification
    ): Promise<any> {
        if (user.type === UserType.COACH) {
            return this.firestore
                .collection('users')
                .doc(user.uid)
                .collection('notifications')
                .doc(id)
                .update(notification);
        } else if (user.type === UserType.CLIENT) {
            return this.firestore
                .collection('users')
                .doc(user.coachId)
                .collection('users')
                .doc(user.uid)
                .collection('notifications')
                .doc(id)
                .update(notification);
        }
    }

    /**
     * Use this function if you want to create a notification as part of making multiple updates at once.
     *
     * @param userRef - User document reference
     *
     * @param type  - type of noificaiton to create
     * @param batch - the batch do hook the notification onto
     */
    createClientNotificationsWithBatch(
        userRef: DocumentReference,
        type: NotificationType,
        batch: firebase.default.firestore.WriteBatch
    ) {
        return batch.set(
            userRef.collection('notifications').doc(),
            this.createNotification(type, userRef),
            {
                merge: true,
            }
        );
    }

    /**
     * Create a notification
     *
     * @param type  - type of noificaiton to create
     * @param coachId - coach id
     * @param clientId - client id (optional)
     */
    createNotificationsWithPromise(
        type: NotificationType,
        coachId: string,
        clientId: string | null = null
    ): Promise<any> {
        const userRef = this.firestore.collection('users').doc(coachId).ref;

        if (clientId) {
            return userRef
                .collection('users')
                .doc(clientId)
                .collection('notifications')
                .add(this.createNotification(type, userRef));
        }

        return userRef
            .collection('notifications')
            .add(this.createNotification(type, userRef));
    }

    /**
     * Create a notification
     *
     * @param user - User document reference
     * @param type - type of notification to create
     */
    private createNotification(
        type: NotificationType,
        user: DocumentReference
    ): Notification {
        //Create default values, let the switch complete the content.
        let notification: PlatformNotifications = {
            created: new Date(),
            userId: user.id,
            icon: '',
            description: '',
            title: '',
            read: false,
        };

        switch (type) {
            case NotificationType.SUPPLEMENT_PLAN_AMENDED:
                notification.icon = 'list_alt';
                notification.description = 'Supplement plan has been updated';
                notification.title = 'Supplement Plan Updated';
                break;

            case NotificationType.NEW_FORM_ASSIGNED:
                notification.icon = 'dynamic_form';
                notification.description = `New form has been assigned to your profile.`;
                notification.title = 'New Form';
                break;

            case NotificationType.FORM_SUBMITTED:
                notification.icon = 'dynamic_form';
                notification.description = `A form has been submitted.`;
                notification.title = 'Form Submitted';
                break;

            default:
                console.error(
                    `Notification not implemented for this event type`
                );
        }

        return notification;
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(user: Coach | NewClient, id: string): Promise<any> {
        if (user.type === UserType.COACH) {
            return this.firestore
                .collection('users')
                .doc(user.uid)
                .collection('notifications')
                .doc(id)
                .delete();
        } else if (user.type === UserType.CLIENT) {
            return this.firestore
                .collection('users')
                .doc(user.coachId)
                .collection('users')
                .doc(user.uid)
                .collection('notifications')
                .doc(id)
                .delete();
        }
    }

    /**
     * Mark all notifications as read
     */
    async markAllAsRead(
        notifications: Notification[],
        user: Coach | NewClient
    ): Promise<any> {
        const batch = this.firestore.firestore.batch();

        notifications.forEach((_) => {
            if (user.type === UserType.COACH) {
                const notification = this.firestore
                    .collection('users')
                    .doc(user.uid)
                    .collection('notifications')
                    .doc(_.id);
                batch.set(notification.ref, { read: true }, { merge: true });
            } else if (user.type === UserType.CLIENT) {
                const notification = this.firestore
                    .collection('users')
                    .doc(user.coachId)
                    .collection('users')
                    .doc(user.uid)
                    .collection('notifications')
                    .doc(_.id);
                batch.set(notification.ref, { read: true }, { merge: true });
            }
        });

        return await batch.commit();
    }

    getNotificationsForUser(user: User): Observable<Notification[]> {
        return this.firestore
            .collectionGroup('notifications', (ref) =>
                ref.where('userId', '==', user?.id).orderBy('created', 'desc')
            )
            .snapshotChanges()
            .pipe(
                map(
                    (actions: DocumentChangeAction<Notification>[]) => {
                        return actions.map(
                            (action: DocumentChangeAction<Notification>) => {
                                const data: any = action.payload.doc.data();
                                const id: string = action.payload.doc.ref.id;
                                return { id, ...data } as Notification;
                            }
                        );
                    },
                    (err) => {
                        console.error(`Error getting notifications`, err);
                    }
                )
            );
    }
}
