import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { ChatService } from '../quick-chat/quick-chat.service';
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { tap, combineLatest } from 'rxjs';
import { Chat } from '../quick-chat/quick-chat.types';
import { UserType } from 'app/enums/user-type.enum';
import { ClientService } from 'app/modules/clients/client.service';
import {
    mapChats,
    mapClientToChatContact,
    mapCoachToChatContact,
} from 'app/modules/admin/apps/chat/chat-helper.helper';
import { UsersService } from 'app/core/services/users/users.service';
import { UserStore } from 'app/core/global-stores/user.store';

@Component({
    selector: 'messages',
    templateUrl: './messages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'messages',
})
export class MessagesComponent
    extends SubscribeUntilDestroyedComponent
    implements OnInit, OnDestroy
{
    @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
    @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

    chats: Chat[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    userType: UserType;
    userId: string;
    user: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _chatService: ChatService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _userStore: UserStore,
        private _clientsService: ClientService,
        private _usersService: UsersService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.subscribe(
            combineLatest(
                this._userStore.getUser,
                this._userStore.getUserType
            ).pipe(
                tap(([user, type]) => {
                    this.user = user;
                    this.userId = user.uid;
                    this.userType = type;
                    if (this.userId) {
                        if (this.userType === UserType.CLIENT) {
                            this.subscribe(
                                combineLatest(
                                    this._usersService.getCoach(user.coachId),
                                    this._chatService.getUnreadMessages(
                                        this.userId,
                                        this.userType
                                    )
                                ).pipe(
                                    tap(([coach, chats]) => {
                                        const chatsWithContact = chats?.map(
                                            (_) => {
                                                _.contact =
                                                    mapCoachToChatContact(
                                                        coach
                                                    );
                                                return _;
                                            }
                                        );

                                        this.chats = chatsWithContact?.filter(
                                            _ =>
                                                !_.mutedBy?.includes(
                                                    this.userId
                                                )
                                        );

                                        // Calculate the unread count
                                        this._calculateUnreadCount();

                                        // Mark for check
                                        this._changeDetectorRef.markForCheck();
                                    })
                                )
                            );
                        } else {
                            this.subscribe(
                                combineLatest(
                                    this._chatService.getUnreadMessages(
                                        this.userId,
                                        this.userType
                                    ),
                                    this._clientsService.getClients(this.userId)
                                ).pipe(
                                    tap(([chats, clients]) => {
                                        //Find contact for chat.
                                        this.chats = mapChats(
                                            chats,
                                            clients,
                                            this.userId
                                        )?.filter(_ => !_.muted);
                                        // Calculate the unread count
                                        this._calculateUnreadCount();

                                        // Mark for check
                                        this._changeDetectorRef.markForCheck();
                                    })
                                )
                            );
                        }
                    }
                })
            )
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the messages panel
     */
    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._messagesPanel || !this._messagesOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._messagesPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the messages panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all messages as read
     */
    markAllAsRead(): void {
        // Mark all as read
        //this._chatService.markAllAsRead(this.chats);
    }

    /**
     * Toggle read status of the given message
     */
    toggleRead(chat: Chat): void {
        // Update the message
        this._chatService.readMessage(chat.id, this.userType);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._messagesOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.chats && this.chats.length) {
            count = this.chats.filter(
                message => message.unreadCount > 0
            ).length;
        }

        this.unreadCount = count;
    }
}
