<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="isScreenSmall ? 'default' : 'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center md:justify-center h-20 mt-3 m-4">
            <img
                class="w-20"
                [src]="coachLogoElseDefault"
                alt="Logo image"
                *ngIf="!loading"
            />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div
    [ngClass]="{
        'mb-12': !isUserCoach
    }"
    class="flex flex-col flex-auto w-full min-w-0"
>
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
        [ngClass]="{ 'pt-2': isScreenSmall }"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->
            <help-widget *ngIf="userType === 1"></help-widget>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <messages class="sm:hidden"></messages>
            <notifications></notifications>

            <user [showAvatar]="true"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 md:z-49 border-t bg-card dark:bg-transparent print:hidden"
        [ngClass]="{ 'mb-12 md:mb-0': isScreenSmall && userType === 2 }"
    >
        <span class="font-medium text-secondary"
            >Powered by CoachKit &copy; {{ currentYear }}</span
        >

        <a
            class="ml-3 text-primary-500 hover:underline"
            [routerLink]="['/legal/privacy']"
            target="_blank"
            >Privacy Policy
        </a>
    </div>  -->
</div>

<mobile-navigation
    *ngIf="isScreenSmall && userType === 2"
    class="fixed bottom-0 z-99 w-full"
></mobile-navigation>
<!-- Quick chat -->
<quick-chat
    #quickChat="quickChat"
    *ngIf="userType === 1"
    [coachId]="userId"
></quick-chat>
