import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import {
    TrainingPlan,
    TrainingPlanWeek,
    TrainingSession,
} from 'app/models/trainingPlan';
import { Exercise } from 'app/models/exercise';
import { ClientsAssigned } from 'app/models/clients-assigned';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { SessionLog } from 'app/models/exerciseSessionLog';

@Injectable({
    providedIn: 'root',
})
export class TrainingPlanNewService {
    constructor(
        private _firestore: AngularFirestore,
        private fns: AngularFireFunctions
    ) {}

    defaultSessions: TrainingSession[] = [
        {
            name: 'Monday',
            exercises: [],
            position: 0,
            dayOfWeek: 1,
        },
        {
            name: 'Tuesday',
            exercises: [],
            position: 1,
            dayOfWeek: 2,
        },
        {
            name: 'Wednesday',
            exercises: [],
            position: 2,
            dayOfWeek: 3,
        },
        {
            name: 'Thursday',
            exercises: [],
            position: 3,
            dayOfWeek: 4,
        },
        {
            name: 'Friday',
            exercises: [],
            position: 4,
            dayOfWeek: 5,
        },
        {
            name: 'Saturday',
            exercises: [],
            position: 5,
            dayOfWeek: 6,
        },
        {
            name: 'Sunday',
            exercises: [],
            position: 6,
            dayOfWeek: 0,
        },
    ];

    getTrainingPlan(coachId: string, id: string): Observable<TrainingPlan> {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc<TrainingPlan>(id)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data = a?.payload?.data();
                        const id = a?.payload?.id;
                        const board = { id, ...data } as TrainingPlan;
                        return board;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    getExercisesForSessions(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        sessions: TrainingSession[]
    ): Observable<Exercise[]> {
        let sessionIds = sessions?.map((_) => _.sessionId);

        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc<TrainingPlan>(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('exercises', (ref) =>
                ref.where('sessionId', 'in', sessionIds)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Exercise>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<Exercise>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;

                            return {
                                id,
                                ...data,
                            } as Exercise;
                        }
                    );
                })
            );
    }

    async deleteExercises(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        exercises: Exercise[]
    ) {
        const batch = this._firestore.firestore.batch();

        //Delete exercises.
        for (let exercise of exercises) {
            const ref = this._firestore
                .collection('users')
                .doc(coachId)
                .collection('trainingPlans')
                .doc<TrainingPlan>(trainingPlanId)
                .collection('weeks')
                .doc(weekNoId)
                .collection('exercises')
                .doc(exercise.id).ref;

            batch.delete(ref);
        }

        return await batch.commit();
    }

    async deletePlan(coachId: string, trainingPlanId: string) {
        const batch = this._firestore.firestore.batch();

        const planRef = this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId).ref;

        batch.delete(planRef);

        //Assigned
        const assigned = await planRef.collection('assigned').get();

        if (assigned?.docs?.length > 0) {
            for (let user of assigned.docs) {
                batch.delete(user.ref);
            }
        }

        //Weeks
        const weeks = await planRef.collection('weeks').get();

        if (weeks?.docs?.length > 0) {
            for (let week of weeks.docs) {
                //Exercises

                const exercises = await week.ref.collection('exercises').get();

                if (exercises?.docs?.length > 0) {
                    for (let exercise of exercises.docs) {
                        batch.delete(exercise.ref);
                    }
                }

                //Sessions
                const sessions = await week.ref.collection('sessions').get();

                if (sessions?.docs?.length > 0) {
                    for (let session of sessions.docs) {
                        batch.delete(session.ref);
                    }
                }

                batch.delete(week.ref);
            }
        }

        return batch.commit();
    }

    getExercise(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        id: string
    ): Observable<Exercise> {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc<TrainingPlan>(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('exercises')
            .doc<Exercise>(id)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data = a?.payload?.data();
                        const id = a?.payload?.id;
                        const card = { id, ...data } as Exercise;

                        return card;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    getTrainingSessions(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string
    ): Observable<TrainingSession[]> {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('sessions', (ref) => ref.orderBy('position', 'asc'))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<TrainingSession>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<TrainingSession>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            const sessionDate =
                                data?.sessionDate?.toDate() ?? null;
                            return {
                                id,
                                ...data,
                                sessionDate,
                            } as TrainingSession;
                        }
                    );
                })
            );
    }

    getExercises(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string
    ): Observable<Exercise[]> {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('exercises', (ref) => ref.orderBy('position', 'asc'))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<Exercise>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<Exercise>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;

                            const trainingBlock = data?.exerciseId
                                ? false
                                : true;

                            return {
                                id,
                                ...data,

                                trainingBlock,
                            } as Exercise;
                        }
                    );
                })
            );
    }

    getTrainingPlans(
        orgId: string,
        coachId: string,
        isAdmin: boolean
    ): Observable<TrainingPlan[]> {
        if (isAdmin) {
            return this._firestore
                .collection('users')
                .doc(orgId)
                .collection('trainingPlans')
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<TrainingPlan>[]) => {
                        return actions.map(
                            (action: DocumentChangeAction<TrainingPlan>) => {
                                const data: any = action.payload.doc.data();
                                const id: string = action.payload.doc.ref.id;
                                return { id, ...data } as TrainingPlan;
                            }
                        );
                    })
                );
        } else {
            return this._firestore
                .collection('users')
                .doc(orgId)
                .collection('trainingPlans', (ref) =>
                    ref.where('createdBy', '==', coachId)
                )
                .snapshotChanges()
                .pipe(
                    map((actions: DocumentChangeAction<TrainingPlan>[]) => {
                        return actions.map(
                            (action: DocumentChangeAction<TrainingPlan>) => {
                                const data: any = action.payload.doc.data();
                                const id: string = action.payload.doc.ref.id;
                                return { id, ...data } as TrainingPlan;
                            }
                        );
                    })
                );
        }
    }

    cloneTrainingPlan(trainingPlan: TrainingPlan) {
        const register = this.fns.httpsCallable('cloneTrainingPlan');
        return register({ trainingPlan: trainingPlan }).toPromise();
    }

    /**
     *
     * Adds a default training plan template including the sessions to week 1.
     *
     * @param orgId - Coach to add the plan to
     * @param trainingPlan - Plan object
     * @param defaultSessions - Default sessions to create
     */
    async addTrainingPlanTemplate(
        orgId: string,
        coachId: string,
        trainingPlan: TrainingPlan,
        addDefaultSessons: boolean = true
    ): Promise<string> {
        const batch = this._firestore.firestore.batch();

        const plan = this._firestore
            .collection('users')
            .doc(orgId)
            .collection('trainingPlans')
            .doc();

        batch.set(
            plan.ref,
            {
                ...trainingPlan,
                createdDate: new Date(),
                lastActivity: new Date(),
                createdBy: coachId,
            },
            { merge: true }
        );

        const weekOne = plan.collection('weeks').doc();

        batch.set(
            weekOne.ref,
            {
                weekNo: 1,
                name: 'Week 1',
                createdDate: new Date(),
                lastActivity: new Date(),
                position: 0,
            },
            { merge: true }
        );

        if (addDefaultSessons) {
            for (let session of this.defaultSessions) {
                const sesh = weekOne.collection('sessions').doc();

                batch.set(sesh.ref, session, { merge: true });
            }
        }

        await batch.commit();

        return plan.ref.id;
    }

    async cloneSessions(
        coachId: string,
        planId: string,
        weekId: string,
        sessions: TrainingSession[]
    ) {
        const batch = this._firestore.firestore.batch();

        const weekRef = this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(planId)
            .collection('weeks')
            .doc(weekId).ref;

        for (let session of sessions) {
            const sessionRef = weekRef.collection('sessions').doc(session.id);
            session.created = new Date();

            batch.set(sessionRef, session, { merge: true });

            if (session.exercises?.length > 0) {
                for (let exercise of session.exercises) {
                    //Exercises.
                    const exerciseRef = weekRef.collection('exercises').doc();
                    exercise.id = exerciseRef.id;
                    exercise.sessionId = session.id;
                    exercise.created = new Date();
                    batch.set(exerciseRef, exercise);
                }
            }
        }

        return await batch.commit();
    }

    updateTrainingPlan(coachId: string, trainingPlan: TrainingPlan) {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlan.id)
            .set(
                {
                    ...trainingPlan,
                },
                { merge: true }
            );
    }

    deleteTrainingPlan(coachId: string, trainingPlan: string) {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlan)
            .delete();
    }

    addSession(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        session: TrainingSession
    ) {
        const ref = this._firestore
            .collection('users')
            .doc(coachId)
            .collection('scrumboards')
            .doc(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('sessions')
            .doc().ref;

        //Add the doc id.
        session.id = ref.id;
        session.created = new Date();
        return ref.set({ ...session });
    }

    updateSessions(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        session: TrainingSession
    ) {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('sessions')
            .doc(session.id)
            .set({ ...session }, { merge: true });
    }

    deleteSession(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        sessionId: string
    ) {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('sessions')
            .doc(sessionId)
            .delete();
    }

    cloneWeek(coachId: string, planId: string, week: TrainingPlanWeek) {
        const batch = this._firestore.firestore.batch();

        const planRef = this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(planId).ref;

        //Week
        const weekRef = planRef.collection('weeks').doc();
        batch.set(weekRef, {
            ...week,
            id: weekRef.id,
            sessions: null,
        });

        //Sessions
        console.log(`Total sessions are: ${week?.sessions}`);
        if (week?.sessions?.length > 0) {
            let exercises = [];

            for (let session of week.sessions) {
                const sessionRef = weekRef.collection('sessions').doc();
                session.created = new Date();
                session.id = sessionRef.id;

                batch.set(sessionRef, session);

                if (session?.exercises?.length > 0) {
                    for (let exercise of session?.exercises) {
                        exercise.sessionId = session.id;
                        exercise.created = new Date();
                        exercises.push(exercise);
                    }
                }
            }

            if (exercises?.length > 0) {
                for (let exercise of exercises) {
                    //Exercises.
                    const exerciseRef = weekRef.collection('exercises').doc();
                    exercise.id = exerciseRef.id;
                    batch.set(exerciseRef, exercise);
                }
            }
        }

        return batch.commit();
    }

    createExercise(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        exercise: Exercise
    ) {
        const ref = this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('exercises')
            .doc().ref;

        //Add the doc id.
        exercise.id = ref.id;
        exercise.created = new Date();

        return ref.set({ ...exercise });
    }

    updateWeek(
        coachId: string,
        planId: string,
        trainingPlanWeek: TrainingPlanWeek
    ) {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(planId)
            .collection('weeks')
            .doc(trainingPlanWeek.id)
            .set(
                {
                    ...trainingPlanWeek,
                },
                { merge: true }
            );
    }

    updateExercise(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        exercise: Exercise
    ) {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('weeks')
            .doc(weekNoId)
            .collection('exercises')
            .doc(exercise.id)
            .set({ ...exercise }, { merge: true });
    }

    updateExercises(
        coachId: string,
        trainingPlanId: string,
        weekNoId: string,
        exercises: Exercise[]
    ) {
        const batch = this._firestore.firestore.batch();
        for (let exercise of exercises) {
            const ref = this._firestore
                .collection('users')
                .doc(coachId)
                .collection('trainingPlans')
                .doc(trainingPlanId)
                .collection('weeks')
                .doc(weekNoId)
                .collection('exercises')
                .doc(exercise.id).ref;
            batch.set(ref, { ...exercise }, { merge: true });
        }
        return batch.commit();
    }

    deleteExercise(
        coachId: string,
        trainingPlanid: string,
        weekNoId: string,
        id: string
    ) {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanid)
            .collection('weeks')
            .doc(weekNoId)
            .collection('exercises')
            .doc(id)
            .delete();
    }

    getWeeks(
        coachId: string,
        trainingPlanId: string
    ): Observable<TrainingPlanWeek[]> {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('weeks', (ref) => ref.orderBy('position', 'asc'))
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<TrainingPlanWeek>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<TrainingPlanWeek>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as TrainingPlanWeek;
                        }
                    );
                })
            );
    }

    async addWeek(
        coachId: string,
        trainingPlanId: string,
        weekNo: number,
        addDefaultSessions: boolean = true
    ) {
        let batch = this._firestore.firestore.batch();

        const weekRef = this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('weeks')
            .doc();

        batch.set(
            weekRef.ref,
            {
                weekNo: weekNo,
                name: `Week ${weekNo}`,
                createdDate: new Date(),
                lastActivity: new Date(),
                //Position starts from 0
                position: weekNo - 1,
            },
            { merge: true }
        );

        if (addDefaultSessions) {
            for (let session of this.defaultSessions) {
                const sesh = weekRef.collection('sessions').doc();

                batch.set(sesh.ref, session, { merge: true });
            }
        }

        await batch.commit();

        return weekRef.ref.id;
    }

    addSessionWithDate(
        orgId: string,
        planId: string,
        weekId: string,
        session: TrainingSession
    ) {
        session.created = new Date();
        return this._firestore
            .collection('users')
            .doc(orgId)
            .collection('trainingPlans')
            .doc(planId)
            .collection('weeks')
            .doc(weekId)
            .collection('sessions')
            .doc(session.id)
            .set(session);
    }

    async deleteWeek(
        coachId: string,
        trainingPlanid: string,
        weekNoId: string
    ) {
        //TODO - Delete sessions and exercises underneath too.

        let batch = this._firestore.firestore.batch();

        const weekRef = this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanid)
            .collection('weeks')
            .doc(weekNoId).ref;

        //Delete sessions.
        const sessions = await weekRef.collection('sessions').get();

        for (let session of sessions.docs) {
            batch.delete(session.ref);
        }

        //Delete exercises.
        const exercises = await weekRef.collection('exercises').get();

        for (let exercise of exercises.docs) {
            batch.delete(exercise.ref);
        }

        //Delete week.
        batch.delete(weekRef);

        return await batch.commit();
    }

    getClientsTrainingPlan(clientId: string): Observable<ClientsAssigned[]> {
        return this._firestore
            .collectionGroup('assigned', (ref) =>
                ref.where('id', '==', clientId)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<ClientsAssigned>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<ClientsAssigned>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            const assignedFrom =
                                data?.assignedFrom?.toDate() ?? null;
                            return {
                                id,
                                ...data,
                                assignedFrom,
                            } as ClientsAssigned;
                        }
                    );
                })
            );
    }

    async updateAssignClients(
        coachId: string,
        trainingPlanId: string,
        clients: ClientsAssigned[]
    ) {
        //Assign clients.
        let batch = this._firestore.firestore.batch();

        const plan = this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId).ref;

        const currenltAssigned = await plan.collection('assigned').get();

        for (let assigned of currenltAssigned.docs) {
            batch.delete(assigned.ref);
        }

        for (let client of clients) {
            const assign = this._firestore
                .collection('users')
                .doc(coachId)
                .collection('trainingPlans')
                .doc(trainingPlanId)
                .collection('assigned')
                .doc(client.id);

            batch.set(
                assign.ref,
                { ...client, created: new Date() },
                { merge: true }
            );
        }

        return await batch.commit();
    }

    async removeClient(
        coachId: string,
        trainingPlanId: string,
        clientId: string
    ) {
        return await this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('assigned')
            .doc(clientId)
            .delete();
    }

    getAssignedClients(
        coachId: string,
        trainingPlanId: string
    ): Observable<ClientsAssigned[]> {
        return this._firestore
            .collection('users')
            .doc(coachId)
            .collection('trainingPlans')
            .doc(trainingPlanId)
            .collection('assigned')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<ClientsAssigned>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<ClientsAssigned>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { id, ...data } as ClientsAssigned;
                        }
                    );
                })
            );
    }
}
