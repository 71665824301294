import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoachService, CoachServicePrice } from './coach-service.model';

@Injectable({
    providedIn: 'root',
})
export class CoachServicesService {
    constructor(
        private firestore: AngularFirestore,
        private fns: AngularFireFunctions
    ) {}

    getCoachServies(orgId: string): Observable<CoachService[]> {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('services')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<CoachService>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<CoachService>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { ...data, id } as CoachService;
                        }
                    );
                })
            );
    }

    getStripeAccountStatus() {
        const getStatus = this.fns.httpsCallable('getStripeAccountStatus');
        return getStatus({}).toPromise();
    }

    getCoachService(
        orgId: string,
        serviceId: string
    ): Observable<CoachService> {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('services')
            .doc<CoachService>(serviceId)
            .snapshotChanges()
            .pipe(
                map(
                    (a: any) => {
                        const data = a?.payload?.data();
                        const id = a?.payload?.id;

                        return { ...data, id } as CoachService;
                    },
                    (error) => {
                        console.log('An error occured: ', error);
                    }
                )
            );
    }

    getPrices(
        orgId: string,
        productId: string
    ): Observable<CoachServicePrice[]> {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('services')
            .doc(productId)
            .collection('prices')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<CoachServicePrice>[]) => {
                    return actions.map(
                        (action: DocumentChangeAction<CoachServicePrice>) => {
                            const data: any = action.payload.doc.data();
                            const id: string = action.payload.doc.ref.id;
                            return { ...data, id } as CoachServicePrice;
                        }
                    );
                })
            );
    }

    addProductAndPricesToStripe(orgId: string, service: CoachService) {
        const syncWithStripe = this.fns.httpsCallable(
            'addProductAndPricesToStripe'
        );
        return syncWithStripe({ orgId: orgId, service: service }).toPromise();
    }

    deletePrice(orgId: string, productId: string, priceId: string) {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('services')
            .doc(productId)
            .collection('prices')
            .doc(priceId)
            .delete();
    }

    deleteMultiplePrices(
        orgId: string,
        productId: string,
        prices: CoachServicePrice[]
    ) {
        const batch = this.firestore.firestore.batch();

        const productRef = this.firestore
            .collection('users')
            .doc(orgId)
            .collection('services')
            .doc(productId).ref;

        for (let price of prices) {
            batch.delete(productRef.collection('prices').doc(price.id));
        }

        return batch.commit();
    }

    createProduct(orgId: string, service: CoachService): Promise<any> {
        const createProduct = this.fns.httpsCallable('createProduct');
        return createProduct({ orgId: orgId, service: service }).toPromise();
    }

    createPrice(orgId: string, productId: string, price: CoachServicePrice) {
        const createPrice = this.fns.httpsCallable('createPrice');
        return createPrice({
            orgId: orgId,
            productId: productId,
            price: price,
        }).toPromise();
    }

    generatePaymentLink(orgId: string, priceId: string): Promise<any> {
        const generatePaymentLink = this.fns.httpsCallable(
            'generatePaymentLink'
        );
        return generatePaymentLink({
            orgId: orgId,
            priceId: priceId,
        }).toPromise();
    }

    updateService(orgId: string, service: CoachService) {
        const serviceDocRef = service?.id
            ? this.firestore
                  .collection('users')
                  .doc(orgId)
                  .collection('services')
                  .doc(service.id).ref
            : this.firestore
                  .collection('users')
                  .doc(orgId)
                  .collection('services')
                  .doc().ref;

        service.id = serviceDocRef.id;

        return serviceDocRef.set(service, { merge: true });
    }

    delete(orgId: string, serviceId: string) {
        return this.firestore
            .collection('users')
            .doc(orgId)
            .collection('services')
            .doc(serviceId)
            .delete();
    }
}
