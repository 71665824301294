import { TrainingPlanWeek } from 'app/models/trainingPlan';

export function calculateTotalWeeks(startDate: Date, endDate: Date): number {
    // Calculate the difference in milliseconds between the two dates
    const timeDifference = endDate.getTime() - startDate.getTime();

    // Calculate the number of milliseconds in a week
    const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;

    // Calculate the total number of weeks
    const totalWeeks = Math.floor(timeDifference / millisecondsInWeek);

    return totalWeeks;
}

export function getCurrentWeek(
    planAssignedFrom: Date,
    weeks: TrainingPlanWeek[]
): any {
    if(!planAssignedFrom) {return;}

    //how many weeks since assigned to plan.
    const totalWeeks = calculateTotalWeeks(planAssignedFrom, new Date());

    let currentWeek = null;

    if (totalWeeks === 0) {
        //They are on their first week.
        currentWeek = weeks?.length > 0 ? weeks[0] : null;
    } else if (weeks?.length > totalWeeks) {
        currentWeek = weeks?.length > 0 ? weeks[totalWeeks] : null;
    } else {
        //On the final week or its repeating.
        currentWeek = weeks?.length > 0 ? weeks[weeks?.length - 1] : null;
    }

    return currentWeek;
}
