import { Component, OnInit } from '@angular/core';
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { AuthService } from 'app/core/auth/auth.service';
import { StripeService } from 'app/core/services/stripe/stripe.service';
import { User } from 'app/models/user';
import { ServicesComponent } from 'app/modules/services/services.component';
import { tap } from 'rxjs';

@Component({
    selector: 'app-import-products',
    templateUrl: './import-products.component.html',
    styleUrls: ['./import-products.component.scss'],
})
export class ImportProductsComponent
    extends SubscribeUntilDestroyedComponent
    implements OnInit
{
    loading: boolean = false;
    user: User;
    constructor(
        private _stripeService: StripeService,
        private _serviceComponent: ServicesComponent,
        private _authService: AuthService
    ) {
        super();
    }

    ngOnInit(): void {
        this.loading = true;

        this._serviceComponent.matDrawer.open();

        this.subscribe(
            this._authService.currentUser.pipe(
                tap((user) => {
                    this.user = user;
                })
            )
        );

        this._stripeService
            .getProducts()
            .then((products) => {
                console.log(`Total products found: ${products?.length}`);
            })
            .catch((err) => {
                console.error(`Error getting products`, err);
            });
    }
}
