<fuse-card class="flex flex-col w-full filter-shopping">
    <div class="m-8 mb-2">
        <div class="flex justify-between" *ngIf="showTitle">
            <div class="flex flex-col">
                <div class="text-2xl leading-tight">
                    {{ title | titlecase }}
                </div>
                <div
                    class="mt-2 items-start flex flex-row mr-1"
                    *ngIf="exercise"
                >
                    <div
                        *ngIf="exercise?.bodyType"
                        class="gap-2 items-center rounded-full px-4 py-1"
                        [ngClass]="{
                            'bg-warn-50 text-warn-700':
                                exercise?.bodyType === 'Upper',
                            'bg-amber-50 text-amber-700':
                                exercise?.bodyType === 'Lower',
                            'bg-green-50 text-green-700':
                                exercise?.bodyType === 'Core'
                        }"
                    >
                        <span>{{ exercise?.bodyType }}</span>
                    </div>

                    <div
                        *ngIf="exercise?.muscleGroup"
                        class="ml-2 gap-2 items-center rounded-full px-4 py-1 bg-blue-50 text-blue-700"
                    >
                        <span>{{ exercise?.muscleGroup }}</span>
                    </div>

                    <div
                        *ngIf="exercise?.type == 3"
                        class="gap-2 items-center rounded-full px-4 py-1 bg-primary-50 text-primary-700"
                    >
                        <span>Crossfit</span>
                    </div>

                    <div
                        *ngIf="exercise?.type == 2"
                        class="ml-2 md:flex gap-2 items-center rounded-full px-4 py-1 bg-amber-50 text-amber-700"
                    >
                        <span>Cardio</span>
                    </div>
                </div>
            </div>

            <button
                *ngIf="showActions"
                class="ml-auto -mr-4"
                mat-icon-button
                [matMenuTriggerFor]="postCardMenu02"
            >
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:dots-vertical'"
                ></mat-icon>
            </button>
            <mat-menu #postCardMenu02="matMenu">
                <ng-container *ngIf="!coachProgrammedExercise?.trainingBlock">
                    <button mat-menu-item (click)="viewExercise()">
                        <span class="flex items-center">
                            <mat-icon
                                class="icon-size-5 mr-3"
                                [svgIcon]="'heroicons_solid:eye'"
                            ></mat-icon>
                            <span>View</span>
                        </span>
                    </button>
                </ng-container>
                <!-- <button mat-menu-item>
                    <span class="flex items-center">
                        <mat-icon
                            class="icon-size-5 mr-3"
                            [svgIcon]="'heroicons_solid:switch-horizontal'"
                        ></mat-icon>
                        <span>Swap exercise</span>
                    </span>
                </button> -->
                <button mat-menu-item (click)="showAddNote(true)">
                    <span class="flex items-center">
                        <mat-icon
                            class="icon-size-5 mr-3"
                            [svgIcon]="'heroicons_solid:chat-alt'"
                        ></mat-icon>
                        <span>Add note</span>
                    </span>
                </button>

                <mat-divider class="my-2"></mat-divider>
                <button mat-menu-item (click)="deleteLog()">
                    <span class="flex items-center">
                        <mat-icon
                            class="icon-size-5 mr-3"
                            [svgIcon]="'heroicons_solid:trash'"
                        ></mat-icon>
                        <span>Remove exercise</span>
                    </span>
                </button>
            </mat-menu>
        </div>

        <hr class="border-b my-6" *ngIf="showTitle" />

        <ng-container *ngIf="coachProgrammedExercise?.exerciseLink">
            <iframe
                class="sm:w-full md:w-1/2 items-center"
                [src]="coachProgrammedExercise.exerciseLink.url | sanitizeUrl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            >
            </iframe>
            <hr class="border-b my-6" />
        </ng-container>

        <ng-container *ngIf="coachProgrammedExercise?.notes">
            <div class="flex flex-col mt-2 mb-2">
                <span class="font-bold"> </span>
                <div class="text-medium font-bold leading-tight">
                    Instructions
                </div>

                <p
                    class="text-seconary"
                    [innerHtml]="coachProgrammedExercise.notes | safeHtml"
                ></p>
            </div>
        </ng-container>

        <ng-container *ngIf="sets?.length; else noSets">
            <div class="text-medium font-bold leading-tight">Sets</div>
            <ol
                (click)="viewExercise()"
                [ngClass]="{ 'cursor-pointer': canViewExercise }"
            >
                <ng-container
                    *ngFor="let step of sets; let i = index; let last = last"
                >
                    <li
                        class="relative group py-6"
                        [ngClass]="{
                            'hover:bg-gray-100': showHover
                        }"
                    >
                        <ng-container *ngIf="!last">
                            <div
                                class="absolute top-6 left-4 w-0.5 h-full -ml-px bg-primary"
                            ></div>
                        </ng-container>
                        <div class="items-center">
                            <div
                                class="relative flex items-start cursor-pointer"
                                (click)="selectSet(i)"
                            >
                                <div
                                    class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring-2 ring-inset bg-card ring-primary"
                                    [ngClass]="{
                                        'bg-primary dark:bg-primary text-on-primary':
                                            step.completed
                                    }"
                                >
                                    <ng-container
                                        *ngIf="
                                            step.completed;
                                            else notCompleted
                                        "
                                    >
                                        <mat-icon
                                            class="icon-size-5 text-current"
                                            [svgIcon]="'heroicons_solid:check'"
                                        ></mat-icon>
                                    </ng-container>
                                    <ng-template #notCompleted>
                                        <div
                                            class="text-md font-semibold text-hint"
                                        >
                                            {{ i + 1 }}
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="ml-4 mt-2">
                                    <div class="font-medium leading-4">
                                        <strong>{{ step.totalReps }}</strong>
                                        reps at
                                        <strong>{{ step.weight }}</strong>
                                        kgs
                                    </div>
                                    <ng-container *ngIf="step.setNotes"
                                        ><div class="text-seconary mt-2">
                                            {{ step.setNotes }}
                                        </div></ng-container
                                    >
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ol>

            <div class="flex items-center" *ngIf="showAddSets">
                <button
                    mat-flat-button
                    class="w-full"
                    [color]="'primary'"
                    (click)="viewExercise()"
                >
                    <mat-icon
                        class="icon-size-5 mr-2"
                        [svgIcon]="'heroicons_outline:plus'"
                    ></mat-icon>
                    Add set
                </button>
            </div>
        </ng-container>

        <ng-template #noSets>
            <empty-state-view
                *ngIf="!coachProgrammedExercise?.trainingBlock"
                (click)="viewExercise()"
                [ngClass]="{ 'cursor-pointer': canViewExercise }"
                class="h-full"
                [icon]="'mat_outline:fitness_center'"
                [title]="'No sets'"
                [subtitle]="'No sets have been logged'"
            >
                <button mat-flat-button [color]="'primary'" type="button">
                    Add set
                </button>
            </empty-state-view>
        </ng-template>

        <mat-form-field
            *ngIf="exercise?.notes || addNote"
            class="fuse-mat-textarea fuse-mat-no-subscript w-full mt-2"
        >
            <mat-label>General notes:</mat-label>
            <textarea
                matInput
                [rows]="1"
                cdkTextareaAutosize
                [value]="exercise.notes ?? ''"
                (change)="updateNotes($event)"
                placeholder="Add notes here"
                [cdkAutosizeMinRows]="2"
            >
            </textarea>
        </mat-form-field>

        <ng-container *ngIf="showActions && exercise">
            <hr class="w-full border-b mt-6 md:mt-auto mb-4" />

            <div class="flex items-center mb-2">
                <button
                    class="px-3 mr-1"
                    mat-stroked-button
                    [color]="exercise.completed ? 'warn' : 'primary'"
                    (click)="
                        exercise.completed
                            ? markAsCompleted(false)
                            : markAsCompleted(true)
                    "
                >
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="
                            exercise.completed
                                ? 'heroicons_solid:minus'
                                : 'heroicons_outline:check'
                        "
                    ></mat-icon>
                    <span class="ml-2">
                        Mark as
                        {{
                            exercise.completed ? "Incomplete" : "Completed"
                        }}</span
                    >
                </button>
                <button
                    class="ml-auto"
                    mat-icon-button
                    (click)="showAddNote(true)"
                >
                    <mat-icon
                        class="icon-size-5 text-hint"
                        [svgIcon]="'heroicons_solid:chat-alt'"
                    ></mat-icon>
                </button>
                <button mat-icon-button>
                    <mat-icon
                        class="icon-size-5 text-hint"
                        [svgIcon]="'heroicons_solid:switch-horizontal'"
                    ></mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
</fuse-card>

<!-- 
<mat-accordion>
    <mat-expansion-panel
        class="border-slate-300 border"
        [expanded]="openByDefault"
    >
        <mat-expansion-panel-header>
            <mat-panel-title class="overflow-hidden flex items-center gap-2">
                <ng-container *ngIf="exercise?.completed; else notCompleted">
                    <mat-icon color="primary" class="icon-size-5"
                        >check</mat-icon
                    >
                </ng-container>
                <ng-template #notCompleted>
                    <mat-icon
                        color="warn"
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:x'"
                    ></mat-icon>
                </ng-template>
                <span class="truncate font-semibold !text-lg">{{
                    title | titlecase
                }}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="border-t border-slate-300">
            <div class="flex flex-col gap-6">
                <ng-container *ngIf="coachProgrammedExercise?.exerciseLink">
                    <iframe
                        width="100%"
                        height="150"
                        [src]="
                            coachProgrammedExercise.exerciseLink.url
                                | sanitizeUrl
                        "
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    >
                    </iframe>
                </ng-container>

                <ng-container *ngIf="coachProgrammedExercise?.notes">
                    <div class="flex flex-col mt-2">
                        <span class="font-bold"> Instructions:</span>

                        <p
                            class="text-seconary"
                            [innerHtml]="
                                coachProgrammedExercise.notes | safeHtml
                            "
                        ></p>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="
                        coachProgrammedExercise &&
                            coachProgrammedExercise.trainingBlock;
                        else defaultExercise
                    "
                >
                

                    <mat-form-field
                        class="fuse-mat-textarea fuse-mat-no-subscript w-full"
                    >
                        <mat-label>General notes:</mat-label>
                        <textarea
                            matInput
                            [rows]="1"
                            cdkTextareaAutosize
                            [value]="exercise.notes ?? ''"
                            (change)="updateNotes($event)"
                            placeholder="Add notes here"
                            [cdkAutosizeMinRows]="2"
                        >
                        </textarea>
                    </mat-form-field>

                    <div class="flex items-center">
                        <button
                            mat-flat-button
                            class="w-full"
                            [color]="exercise.completed ? 'warn' : 'primary'"
                            (click)="
                                exercise.completed
                                    ? markAsCompleted(false)
                                    : markAsCompleted(true)
                            "
                        >
                            <mat-icon
                                class="icon-size-5 mr-2"
                                [svgIcon]="
                                    exercise.completed
                                        ? 'heroicons_outline:trash'
                                        : 'heroicons_outline:check'
                                "
                            ></mat-icon>
                            Mark as
                            {{
                                exercise.completed ? "Incomplete" : "Completed"
                            }}
                        </button>
                    </div>
                </ng-container>

                <ng-template #defaultExercise>
                    <ng-container *ngIf="sets?.length; else noSets">
                        <ol
                            (click)="viewExercise()"
                            [ngClass]="{ 'cursor-pointer': canViewExercise }"
                        >
                            <ng-container
                                *ngFor="
                                    let step of sets;
                                    let i = index;
                                    let last = last
                                "
                            >
                                <li
                                    class="relative group py-6"
                                    [ngClass]="{
                                        'hover:bg-gray-100': showHover
                                    }"
                                >
                                    <ng-container *ngIf="!last">
                                        <div
                                            class="absolute top-6 left-4 w-0.5 h-full -ml-px bg-primary"
                                        ></div>
                                    </ng-container>
                                    <div class="items-center">
                                        <div
                                            class="relative flex items-start cursor-pointer"
                                            (click)="selectSet(i)"
                                        >
                                            <div
                                                class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring-2 ring-inset bg-card ring-primary"
                                                [ngClass]="{
                                                    'bg-primary dark:bg-primary text-on-primary':
                                                        step.completed
                                                }"
                                            >
                                                <ng-container
                                                    *ngIf="
                                                        step.completed;
                                                        else notCompleted
                                                    "
                                                >
                                                    <mat-icon
                                                        class="icon-size-5 text-current"
                                                        [svgIcon]="
                                                            'heroicons_solid:check'
                                                        "
                                                    ></mat-icon>
                                                </ng-container>
                                                <ng-template #notCompleted>
                                                    <div
                                                        class="text-md font-semibold text-hint"
                                                    >
                                                        {{ i + 1 }}
                                                    </div>
                                                </ng-template>
                                            </div>
                                            <div class="ml-4 mt-2">
                                                <div
                                                    class="font-medium leading-4"
                                                >
                                                    <strong>{{
                                                        step.totalReps
                                                    }}</strong>
                                                    reps at
                                                    <strong>{{
                                                        step.weight
                                                    }}</strong>
                                                    kgs
                                                </div>
                                                <ng-container
                                                    *ngIf="step.setNotes"
                                                    ><div
                                                        class="text-seconary mt-2"
                                                    >
                                                        {{ step.setNotes }}
                                                    </div></ng-container
                                                >
                                            </div>
                                     
                                        </div>
                                    </div>
                                </li>
                            </ng-container>
                        </ol>
                    </ng-container>

                    <div class="flex items-center" *ngIf="sets?.length > 0">
                        <button
                            mat-flat-button
                            class="w-full"
                            [color]="'primary'"
                            (click)="viewExercise()"
                        >
                            <mat-icon
                                class="icon-size-5 mr-2"
                                [svgIcon]="'heroicons_outline:plus'"
                            ></mat-icon>
                            Add sets
                        </button>
                    </div>

                    <ng-container *ngIf="exercise?.notes">
                        <div class="flex flex-col mt-2">
                            <span class="font-bold">Your Notes:</span>
                            <span class="text-seconary">
                                {{ exercise.notes }}</span
                            >
                        </div>
                    </ng-container>
                </ng-template>

                <ng-template #noSets>
                    <empty-state-view
                        (click)="viewExercise()"
                        [ngClass]="{ 'cursor-pointer': canViewExercise }"
                        class="h-full"
                        [icon]="'mat_outline:fitness_center'"
                        [title]="'No sets'"
                        [subtitle]="'No sets have been logged'"
                    >
                        <button
                            mat-flat-button
                            [color]="'primary'"
                            type="button"
                        >
                            Add set
                        </button>
                    </empty-state-view>
                </ng-template>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion> -->
