import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { AddPriceComponent } from '../add-price/add-price.component';
import { CoachService } from '../coach-service.model';
import { CoachServicesService } from '../coachServices.service';
import { SelectPriceComponent } from '../generate-payment-link/select-price/select-price.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'service-card',
    templateUrl: './service-card.component.html',
    styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit {
    @Input()
    service: CoachService;

    @Input()
    isAdmin: boolean = false;

    @Input()
    orgId: string;

    generating: boolean = false;

    paymentLink: string;

    addingToStripe: boolean = false;

    @Input()
    stripeEnabled: boolean = false;

    @Output()
    editService: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _coachService: CoachServicesService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _snackbarService: SnackbarService,
        private _matDialog: MatDialog,
        private _clipboard: Clipboard
    ) {}

    ngOnInit(): void {}

    edit() {
        this.editService.emit(true);
    }

    delete() {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title: 'Delete service',

            message:
                'Are you sure you want to remove this service? <br> <br> Please note if the Stripe integration is enabled this product <strong> will NOT </strong> be removed from Stripe upon the service being removed.',
            actions: {
                confirm: {
                    label: 'Yes',
                },
            },
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe(async (result) => {
            // If the confirm button pressed...
            if (result === 'confirmed') {
                // Delete the service

                try {
                    await this._coachService.delete(
                        this.orgId,
                        this.service.id
                    );
                    this._snackbarService.success(
                        'Service deleted successfully'
                    );
                } catch (err) {
                    console.error(`Unable to delete service`, err);
                    this._snackbarService.error('Unable to delete service');
                }
            }
        });
    }

    async addToStripe() {
        this.addingToStripe = true;

        try {
            await this._coachService.addProductAndPricesToStripe(
                this.orgId,
                this.service
            );
            this._snackbarService.success(
                'Product added to Stripe successfully'
            );
            this.addingToStripe = false;
        } catch (err) {
            console.error(`Unable to add product to stripe`, err);
            this.addingToStripe = false;
            this._snackbarService.error('Unable to add product to stripe');
        }
    }

    copyToClipboard(toCopy: string) {
        this._clipboard.copy(toCopy);
        this._snackbarService.success('Link copied to clipboard');
    }

    async generatePaymentLink() {
        try {
            if (this.service.prices?.length > 1) {
                //Open modal.

                const dialogRef = this._matDialog.open(SelectPriceComponent, {
                    data: { prices: this.service.prices },
                });

                dialogRef.afterClosed().subscribe(async (result) => {
                    if (result?.selected) {
                        this.generating = true;

                        const stripeLink =
                            await this._coachService.generatePaymentLink(
                                this.orgId,
                                result.selected.stripe?.id
                            );

                        if (stripeLink?.success) {
                            this.paymentLink = stripeLink?.paymentLink?.url;
                        } else {
                            this._snackbarService.error(
                                `Unable to generate payment link. Stripe error: ${stripeLink?.error?.message} `
                            );
                        }

                        this.generating = false;
                    }
                });
            } else {
                //Generate for only price available.
                this.generating = true;

                const stripeLink = await this._coachService.generatePaymentLink(
                    this.orgId,
                    this.service.prices[0].stripe?.id
                );

                if (stripeLink?.success) {
                    this.paymentLink = stripeLink?.paymentLink?.url;
                } else {
                    this._snackbarService.error(
                        `Unable to generate payment link. Stripe error: ${stripeLink?.error?.message} `
                    );
                }

                this.generating = false;
            }
        } catch (err) {
            this.generating = false;
            console.error(`Unable to generate payment link`, err);
        }
    }

    addPrice() {
        const dialogRef = this._matDialog.open(AddPriceComponent, {
            data: { orgId: this.orgId, productId: this.service.id },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.success) {
                this._snackbarService.success('Price added successfully');
            }
        });
    }
}
