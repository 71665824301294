<fuse-card class="flex flex-col w-full filter-shopping">
    <div class="m-8 mb-2">
        <div class="flex justify-between">
            <div class="flex flex-col">
                <div class="text-2xl leading-tight">
                    {{ title | titlecase }}
                </div>
                <div
                    class="mt-2 items-start flex flex-row mr-1"
                    *ngIf="exercise"
                >
                    <div
                        *ngIf="exercise?.bodyType"
                        class="gap-2 items-center rounded-full px-4 py-1"
                        [ngClass]="{
                            'bg-warn-50 text-warn-700':
                                exercise?.bodyType === 'Upper',
                            'bg-amber-50 text-amber-700':
                                exercise?.bodyType === 'Lower',
                            'bg-green-50 text-green-700':
                                exercise?.bodyType === 'Core'
                        }"
                    >
                        <span>{{ exercise?.bodyType }}</span>
                    </div>

                    <div
                        *ngIf="exercise?.muscleGroup"
                        class="gap-2 items-center rounded-full px-4 py-1 bg-blue-50 text-blue-700"
                    >
                        <span>{{ exercise?.muscleGroup }}</span>
                    </div>

                    <div
                        *ngIf="exercise?.type == 3"
                        class="gap-2 items-center rounded-full px-4 py-1 bg-primary-50 text-primary-700"
                    >
                        <span>Crossfit</span>
                    </div>

                    <div
                        *ngIf="exercise?.type == 2"
                        class="ml-2 md:flex gap-2 items-center rounded-full px-4 py-1 bg-amber-50 text-amber-700"
                    >
                        <span>Cardio</span>
                    </div>
                </div>
            </div>
        </div>

        <hr class="border-b my-6" />

        <ng-container *ngIf="exerciseLink">
            <iframe
                class="w-full"
                [src]="exerciseLink.url | sanitizeUrl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            >
            </iframe>
            <hr class="border-b my-6" />
        </ng-container>

        <ng-container *ngIf="instructions">
            <div class="flex flex-col mt-2 mb-2">
                <span class="font-bold"> </span>
                <div class="text-medium font-bold leading-tight">
                    Instructions
                </div>

                <p
                    class="text-seconary"
                    [innerHtml]="instructions | safeHtml"
                ></p>
            </div>
        </ng-container>

        <ng-container *ngIf="sets?.length">
            <div class="text-medium font-bold leading-tight">Sets</div>
            <ol [ngClass]="{ 'cursor-pointer': canViewExercise }">
                <ng-container
                    *ngFor="let step of sets; let i = index; let last = last"
                >
                    <li
                        class="relative group py-6"
                        [ngClass]="{
                            'hover:bg-gray-100': showHover
                        }"
                    >
                        <ng-container *ngIf="!last">
                            <div
                                class="absolute top-6 left-4 w-0.5 h-full -ml-px bg-primary"
                            ></div>
                        </ng-container>
                        <div class="items-center">
                            <div
                                class="relative flex items-start cursor-pointer"
                            >
                                <div
                                    class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring-2 ring-inset bg-card ring-primary"
                                    [ngClass]="{
                                        'bg-primary dark:bg-primary text-on-primary':
                                            step.completed
                                    }"
                                >
                                    <ng-container
                                        *ngIf="
                                            step.completed;
                                            else notCompleted
                                        "
                                    >
                                        <mat-icon
                                            class="icon-size-5 text-current"
                                            [svgIcon]="'heroicons_solid:check'"
                                        ></mat-icon>
                                    </ng-container>
                                    <ng-template #notCompleted>
                                        <div
                                            class="text-md font-semibold text-hint"
                                        >
                                            {{ i + 1 }}
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="ml-4 mt-2">
                                    <div class="font-medium leading-4">
                                        <strong>{{ step.totalReps }}</strong>
                                        reps at
                                        <strong>{{ step.weight }}</strong>
                                        kgs
                                    </div>
                                    <ng-container *ngIf="step.setNotes"
                                        ><div class="text-seconary mt-2">
                                            {{ step.setNotes }}
                                        </div></ng-container
                                    >
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ol>
        </ng-container>

        <ng-container *ngIf="clientNotes">
            <div class="flex flex-col mt-2 mb-2">
                <span class="font-bold"> </span>
                <div class="text-medium font-bold leading-tight">
                    Client notes
                </div>

                <p
                    class="text-seconary"
                    [innerHtml]="clientNotes | safeHtml"
                ></p>
            </div>
        </ng-container>
    </div>
</fuse-card>
