// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // Set to true to use emulators for local development
    // you can also use firebase emulators:start --import ./emulator-data --export-on-exit
    useEmulators: false,

    firebaseConfig: {
        apiKey: 'AIzaSyChP7C1vTZARz-U7g0FODplDikKSGx_k_Y',
        authDomain: 'one-coach.firebaseapp.com',
        projectId: 'one-coach',
        storageBucket: 'one-coach.appspot.com',
        messagingSenderId: '10348989130',
        appId: '1:10348989130:web:b11f47e48c0752cca94233',
        measurementId: 'G-FMBVM37FCR',
        functions: {
            host: 'https://europe-west2-one-coach.cloudfunctions.net',
        },
    },

    BASE_URL: 'https://dev.coachkit.co.uk',

    PROSPECT_FORM: {
        FIRST_NAME_QID: 'RcjY8GtS1zJ5II2rFaio',
        LAST_NAME_QID: 'hrs7G3j2YRZ8O6tFRA7N',
        EMAIL_QID: '2toidqIFnraNVzKgqToe',
    },

    CALENDLY: {
        CLIENT_ID: 'XxZYCmK3F471a_NadCg2rg13ezoFS4GiYLF2rodf-6w',
        // Client secret is not needed for the client-side flow
    },

    POSTCODE_LOOKUP: 'https://api.postcodes.io',

    // Global Variables
    // * File uploads
    FILES_LIMIT: 5,
    MAX_SIZE: 10485760, // 10MB
    ACCEPT_TYPES: 'video/*,image/*,.pdf',
    FILE_TYPES: [
        // Word (doc, docx)
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // Open Office (odt, ott, sheet, presentation)
        'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.presentation',
        // Text (txt, rtf, pdf)
        'text/plain',
        'application/rtf',
        'application/pdf',
        // Excel (xls, xlsx)
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // PowerPoint (ppt, pptx)
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        // Image (jpg, jpeg, png, gif)
        'image/jpeg',
        'image/png',
        'image/gif',
    ],

    LOGGING: {
        MS_CLARITY: {
            ENABLED: true,
            PROJECT_ID: 'kove3vf72x',
        },
    },

    CONFIG: {
        showHelpCenter: true,
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
