import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MobileNavigationComponent } from './mobile-navigation.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    declarations: [MobileNavigationComponent],
    imports: [CommonModule, MatIconModule, RouterModule, MatTabsModule],
    exports: [MobileNavigationComponent],
})
export class MobileNavigationModule {}
