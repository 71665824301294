export enum NotificationType {
    // CHECKIN_COMPLETED = 1, - Done in functions
    // CHECKIN_REVIWED = 2, - Done in functions
    PAYMENT_RECEIVED = 3,
    // TRAINING_PLAN_AMENDED = 4,
    // NUTRITION_PLAN_AMENDED = 5,
    SUPPLEMENT_PLAN_AMENDED = 6,
    FORM_SUBMITTED = 7,
    NEW_FORM_ASSIGNED = 8,
}
