import { Component, Inject, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { UserStore } from 'app/core/global-stores/user.store';
import { PaymentFrequency } from 'app/enums/payment-frequency.enum';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { CoachServicePrice } from '../coach-service.model';
import { CoachServicesService } from '../coachServices.service';

@Component({
    selector: 'add-price',
    templateUrl: './add-price.component.html',
    styleUrls: ['./add-price.component.scss'],
})
export class AddPriceComponent
    extends SubscribeUntilDestroyedComponent
    implements OnInit
{
    form: FormGroup;

    loading: boolean = false;

    productId: string;

    orgId: string;

    reoccuringPaymentFrequency = [
        { value: PaymentFrequency.ONE_OFF, label: 'One Off' },
        { value: PaymentFrequency.MONTHLY, label: 'Every month' },
        { value: PaymentFrequency.WEEKLY, label: 'Every Week' },
        { value: PaymentFrequency.DAILY, label: 'Every day' },
    ];

    constructor(
        private _formBuilder: FormBuilder,
        private _coachServices: CoachServicesService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<AddPriceComponent>,
        private _snackbarService: SnackbarService
    ) {
        super();
    }

    ngOnInit(): void {
        this.productId = this.data.productId;
        this.orgId = this.data.orgId;
        this.form = this._formBuilder.group({
            amount: ['', Validators.required],
            //One off or reccurring?
            paymentFrequency: [
                this.reoccuringPaymentFrequency[0],
                [Validators.required],
            ],
        });
    }

    async save() {
        if (!this.form.valid) {
            return;
        }

        try {
            this.loading = true;

            const price: CoachServicePrice = {
                amount: this.form.value.amount,
                paymentFrequency: this.form.value.paymentFrequency.value,
            };

            await this._coachServices.createPrice(
                this.orgId,
                this.productId,
                price
            );
            this.matDialogRef.close({ success: true });
            this.loading = false;
        } catch (err) {
            console.error(`Unable to create price`, err);
            this._snackbarService.error(`Unable to add price, try again later`);
            this.loading = false;
        }
    }
}
