import { Component, Inject, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscribeUntilDestroyedComponent } from 'app/common/subscribe-until-destroyed.component';
import { DurationType } from 'app/models/duration';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { take, tap } from 'rxjs';
import { CoachService, CoachServicePrice } from '../coach-service.model';
import { CoachServicesService } from '../coachServices.service';
import { UserStore } from 'app/core/global-stores/user.store';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'add-service',
    templateUrl: './add-service.component.html',
    styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent
    extends SubscribeUntilDestroyedComponent
    implements OnInit
{
    form: FormGroup;

    loading: boolean = false;

    planDurationOptions = [
        {
            value: DurationType.MONTHS,
            label: 'Months',
        },
        {
            value: DurationType.WEEKS,
            label: 'Weeks',
        },
        {
            value: DurationType.DAYS,
            label: 'Days',
        },
    ];

    orgId: string;

    service: CoachService;

    pricesToDelete: CoachServicePrice[] = [];

    constructor(
        private _formBuilder: FormBuilder,
        private _coachServices: CoachServicesService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<AddServiceComponent>,
        private _userStore: UserStore,
        private _snackbarService: SnackbarService
    ) {
        super();
    }

    ngOnInit(): void {
        this.service = this.data?.service ?? null;

        this.subscribe(
            this._userStore.getCoachingBusinessId.pipe(
                take(1),
                tap((orgId: string) => {
                    this.orgId = orgId;
                })
            )
        );

        const durationType = this.service?.duration
            ? this.planDurationOptions?.find(
                  (_) => _.value === this.service.duration.type
              )?.value
            : this.planDurationOptions[1].value;

        this.form = this._formBuilder.group({
            name: [this.service?.name ?? '', Validators.required],
            description: [this.service?.description ?? '', []],
            duration: [
                this.service?.duration.amount ?? 0,
                [Validators.required, Validators.min(1), Validators.max(1000)],
            ],
            durationType: [durationType, Validators.required],
        });
    }

    async deletePrice(index: number, price: CoachServicePrice) {
        this.service.prices.splice(index, 1);
        this.pricesToDelete.push(price);
    }

    async save() {
        if (!this.form.valid) {
            return;
        }

        try {
            this.loading = true;

            const service: CoachService = {
                id: this.service?.id ?? null,
                name: this.form.value.name,
                created: this.service?.created ?? new Date(),
                lastUpdated: new Date(),
                duration: {
                    amount: this.form.value.duration,
                    type: this.form.value.durationType,
                },
                stripe: this.service?.stripe ?? null,
            };

            await this._coachServices.createProduct(this.orgId, service);

            //Delete prices.
            if (this.pricesToDelete?.length > 0) {
                try {
                    await this._coachServices.deleteMultiplePrices(
                        this.orgId,
                        this.service.id,
                        this.pricesToDelete
                    );
                } catch (err) {
                    console.error('Unable to delete prices');
                    this._snackbarService.error(
                        'Unable to delete prices, try again later.'
                    );
                }
            }

            this.matDialogRef.close({ success: true });
            this.loading = false;
        } catch (err) {
            console.error('Unable to update product', err);
            this._snackbarService.error(
                'Unable to update product, try again later'
            );
            this.loading = false;
        }
    }

    resetAndClearValidators(control: AbstractControl) {
        control.reset();
        control.clearValidators();
        control.updateValueAndValidity();
    }

    cancel() {
        if (this.pricesToDelete?.length > 0) {
            this.service.prices = this.pricesToDelete;
        }
        this.matDialogRef.close();
    }
}
