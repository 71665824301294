import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    DocumentChangeAction,
} from '@angular/fire/compat/firestore';

import { User } from 'app/models/user';
import { Observable, BehaviorSubject, of, from, forkJoin } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { QuestionBase } from 'app/models/questions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { environment } from 'environments/environment';
import { StorageService } from '../storage/storage.service';
import {
    AngularFireStorageReference,
    AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
    providedIn: 'root',
})
export class FormsService {
    constructor(
        private firestore: AngularFirestore,
        private storage: StorageService,
        private _notificationsService: NotificationsService,
        private _snackbarService: SnackbarService,
        private _httpClient: HttpClient,
        private _fns: AngularFireFunctions
    ) {}

    getCoachForms(
        coachId: string,
        collectionName: string = 'forms'
    ): Observable<any[]> {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection(collectionName)
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) =>
                    actions.map((action: DocumentChangeAction<any>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as any;
                    })
                )
            );
    }

    async getSubmittedForm(
        coachId: string,
        submittedFormId: string,
        formCollection: string = 'standard-form-submissions',
        clientId: string = null
    ) {
        if (!submittedFormId) {
            return of(null);
        }

        if (clientId) {
            return this.firestore
                .collection('users')
                .doc(coachId)
                .collection('users')
                .doc(clientId)
                .collection(formCollection)
                .doc(submittedFormId)
                .get()
                .pipe(map((doc: any) => doc.data()));
        }

        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection(formCollection)
            .doc(submittedFormId)
            .get()
            .pipe(
                take(1),
                map((doc: any) => doc.data())
            );
    }

    getAllClientSubmissions(
        coachId: string,
        collectionName: string = 'standard-form-submissions'
    ) {
        return this.firestore
            .collectionGroup(collectionName, ref =>
                ref.where('coachId', '==', coachId)
            )
            .get()
            .pipe(map((doc: any) => doc.docs.map((item: any) => item.data())));
    }

    getAllPublicSubmissions(coachId: string) {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('public-form-submissions')
            .get()
            .pipe(map((doc: any) => doc.docs.map((item: any) => item.data())));
    }

    getAllProspectSubmissions(coachId: string) {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('prospect-form-submissions', ref => ref.where('status', '!=', 10))
            .get()
            .pipe(map((doc: any) => doc.docs.map((item: any) => item.data())));
    }

    getCoachForm(
        coachId: string,
        formId: string,
        collectionName: string = 'forms'
    ): Observable<any> {
        return this.firestore
            .collection('users')
            .doc<User>(coachId)
            .collection(collectionName)
            .doc<any>(formId)
            .get()
            .pipe(map(doc => doc.data()));
    }

    getFormByCollectionGroup(formId: string, collectionName: string = 'forms') {
        return this.firestore
            .collectionGroup(collectionName, ref =>
                ref.where('id', '==', formId)
            )
            .get()
            .pipe(
                take(1),
                map((doc) => {
                    if (doc.empty) {
                        return null;
                    }

                    return doc.docs[0].data();
                })
            );
    }

    getFormTemplates(): Observable<any[]> {
        return this.firestore
            .collection('formTemplates')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) =>
                    actions.map((action: DocumentChangeAction<any>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as any;
                    })
                )
            );
    }

    getDefaultQuestions(): Observable<any[]> {
        return this.firestore
            .collection('defaultQuestions', ref =>
                ref.orderBy('defaultOrder', 'asc')
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) =>
                    actions.map((action: DocumentChangeAction<any>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as any;
                    })
                )
            );
    }

    getDefaultMetrics(): Observable<any[]> {
        return this.firestore
            .collection('defaultMetrics')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) =>
                    actions.map((action: DocumentChangeAction<any>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as any;
                    })
                )
            );
    }

    getDefaultMeasurements(): Observable<any[]> {
        return this.firestore
            .collection('defaultMeasurements')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) =>
                    actions.map((action: DocumentChangeAction<any>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as any;
                    })
                )
            );
    }

    getFormFromUsername(username: string, formId: string): Observable<any> {
        return this.firestore
            .collection('usernames')
            .doc(username)
            .get()
            .pipe(
                switchMap((doc: any) =>
                    this.firestore
                        .collection('users')
                        .doc(doc.data().coachId)
                        .collection('forms')
                        .doc(formId)
                        .get()
                        .pipe(map((form: any) => form.data()))
                )
            );
    }

    getInCompleteForms(coachId: string, clientId: string): Observable<any[]> {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection('standard-forms', ref =>
                ref.where('completed', '==', false)
            )
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) =>
                    actions.map((action: DocumentChangeAction<any>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as any;
                    })
                )
            );
    }

    getClientCheckinForms(userId: string, coachId: string): Observable<any[]> {
        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(userId)
            .collection('checkin-forms')
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<any>[]) =>
                    actions.map((action: DocumentChangeAction<any>) => {
                        const data: any = action.payload.doc.data();
                        const id: string = action.payload.doc.ref.id;
                        return { id, ...data } as any;
                    })
                )
            );
    }

    async saveFormToCollection(formDetails: any) {
        //Inform coach of new starter form completed.
        const batch = this.firestore.firestore.batch();

        const formId = formDetails.id ?? this.firestore.createId();
        formDetails.id = formId;

        const isPublicForm =
            formDetails?.isProspectForm || formDetails?.isPublic;

        const formRef = this.firestore
            .collection('users')
            .doc(formDetails.uid)
            .collection(isPublicForm ? 'public-forms' : 'forms')
            .doc(formId).ref;

        // Comment out to save to formTemplates
        // const formRef = this.firestore.collection('formTemplates').doc().ref;

        // Save the form
        batch.set(
            formRef,
            {
                ...formDetails,
            },
            { merge: true }
        );

        // Save the form
        return batch
            .commit()
            .then(res => formRef.id)
            .catch((error) => {
                console.log('An error occured: ', error);
            });
    }

    /**
     * Create duplicate of form
     *
     * @param formId - the id of the form to duplicate
     * @param coachId - the id of the coach
     * @param formCollection - the collection name where the form is stored
     */
    async duplicateForm(
        formId: string,
        coachId: string,
        formCollection: string = 'forms'
    ) {
        const form = await this.getCoachForm(coachId, formId, formCollection)
            .pipe(take(1))
            .toPromise();

        if (!form) {
            return;
        }

        if (form.updatedAt) {
            delete form.updatedAt;
        }

        const newFormId = this.firestore.createId();
        const formName = form.name + ' - Copy';
        const newForm = {
            ...form,
            id: newFormId,
            name: formName,
            updatedAt: new Date().toISOString(),
            link: form.link + '-copy',
        };

        return this.saveFormToCollection(newForm);
    }

    deleteForm(
        formId: string,
        coachId: string,
        formCollection: string = 'forms'
    ) {
        const formRef = this.firestore
            .collection('users')
            .doc(coachId)
            .collection(formCollection)
            .doc(formId).ref;

        return formRef.delete();
    }

    /********************/
    /* Form Submissions */
    /********************/

    /**
     * This function will save the form to the database - if file upload use the submitFormWithFileUploads function
     *
     * @param submittedForm - the form data
     * @param responses - the form responses
     * @param additionalData - any additional data to save to the form
     * @param submittedFormId - the id of the submission
     * @param submissionDocRef - the reference to the submission document
     * @param assignedFormDocRef - the reference to the assigned form document
     * @returns
     */
    async submitFormWithoutUploads(
        submittedForm: any,
        responses: any,
        additionalData: any = {},
        submittedFormId: string,
        submissionDocRef: any,
        assignedFormDocRef: any | null = null,
        isPreviouslySubmitted: boolean = false
    ): Promise<any> {
        // console.log('>>>> Submitting form without uploads ...');

        this._snackbarService.info(
            'Please wait while your form is being submitted.',
            'Submitting Form'
        );

        const taskStatus = new BehaviorSubject<any>({
            status: 'uploading',
            submittedFormId,
        }); // status of the upload
        // const batch = this.firestore.firestore.batch();

        // add answers to the form by finding the question in the form and adding the answer
        for await (const section of submittedForm.questions) {
            for await (const question of section.sectionQuestions) {
                const response = responses.find(
                    item => item.id === question.id
                );
                if (!response) {
                    continue;
                }

                // console.log('>>>> Saving answer...', response);

                question.answer =
                    response?.value?.value ?? response?.value ?? null;

                if (response.deleteAnswer) {
                    // add delete request to the delete-requests collection
                    try {
                        await this.addToDeleteRequests(submittedFormId, {
                            questionId: question.id,
                            coachId: submittedForm.coachId,
                            clientId: submittedForm.clientId,
                            prop: this.getPropLabel(response) as any,
                            propId: this.getPropId(response),
                            formId: submittedForm.formId,
                        });
                    } catch (error) {
                        console.log(
                            `>>>> Error adding delete request: ${error}`
                        );
                    }
                    // delete the answer from the question
                    question.answer = null;
                }

                if (
                    response.hasOwnProperty('metric') &&
                    response?.metric !== null &&
                    response?.metric >= 0 &&
                    question.answer !== null
                ) {
                    question.metric = response.metric;
                } else if (
                    response.hasOwnProperty('measurement') &&
                    response?.measurement !== null &&
                    response?.measurement >= 0 &&
                    question.answer !== null
                ) {
                    question.measurement = response.measurement;
                }
            }
        }

        if (assignedFormDocRef) {
            await assignedFormDocRef.set(
                {
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    submittedFormId: submittedFormId ?? null,
                    status: 2, // Submitted
                    completed: true,
                },
                { merge: true }
            );
        }

        const submissionDocPayload = {
            ...submittedForm,
            submittedFormId,
            ...additionalData,
            created: new Date(),
        };

        if (isPreviouslySubmitted) {
            delete submissionDocPayload['created'];
            submissionDocPayload['updatedAt'] = new Date();
        }

        await submissionDocRef.set(submissionDocPayload as any, {
            merge: true,
        });

        taskStatus.next({ status: 'success', submittedFormId });

        return submittedFormId;
    }

    async submitFormWithFileUploads(
        submittedForm: any,
        responses: any,
        additionalData: any = {},
        submittedFormId: string,
        uploadPath: string,
        submissionDocRef: any,
        assignedFormDocRef: any | null = null,
        isPreviouslySubmitted: boolean = false
    ): Promise<any> {
        // console.log('>>>> Submitting form with uploads ...');

        this._snackbarService.info(
            'Please wait while your form is being submitted.',
            'Submitting Form'
        );

        for await (const section of submittedForm.questions) {
            for await (const question of section.sectionQuestions) {
                const response = responses.find(
                    item => item.id === question.id
                );

                if (!response) {
                    continue;
                }

                if (question.controlType === 'FILE_UPLOAD') {
                    // console.log('>>>> Handling files...', response.value);
                    const files: any[] = [];
                    for await (const file of response.value) {
                        if (!file) {
                            continue;
                        }

                        // console.log('>>>> Handling file...', file);

                        if (file.deleteAnswer) {
                            // onCreate trigger will delete the files from storage and docs from relevant collections
                            // add delete request to the delete-requests collection
                            try {
                                // console.log('>>>> Adding delete request...');
                                await this.addToDeleteRequests(
                                    submittedFormId,
                                    {
                                        questionId: question.id,
                                        coachId: submittedForm.coachId,
                                        clientId: submittedForm.clientId,
                                        prop: this.getPropLabel(
                                            response
                                        ) as any,
                                        propId: this.getPropId(response),
                                        formId: submittedForm.formId,
                                        file: file.downloadURL,
                                    }
                                );
                            } catch (error) {
                                console.log(
                                    `>>>> Error adding delete request: ${error}`
                                );
                            }

                            continue; // go to next file
                        }

                        if (file.fullPath && file.downloadURL) {
                            files.push({
                                downloadURL: file.downloadURL,
                                fullPath: file.fullPath,
                            });
                            question.answer = [...files];
                            // console.log('>>>> File already uploaded...');
                            continue; // go to next file
                        }

                        // Fall through to upload file
                        // upload file to storage with uuid as filename
                        const uuidFilename = uuidv4();
                        const fullPath = `${uploadPath}/${response.id}/${uuidFilename}`;
                        // do the upload
                        const upload = this.storage.uploadFilePromise(
                            file,
                            fullPath,
                            {
                                name: uuidFilename,
                                type: file?.type,
                                size: file?.size,
                            }
                        );

                        await upload.then(
                            async (res: AngularFireUploadTask) => {
                                const result = await res.task;
                                const downloadURL =
                                    await result.ref.getDownloadURL();
                                const currentFile = {
                                    downloadURL,
                                    fullPath,
                                };
                                files.push(currentFile);

                                // console.log('>>>> File uploaded...', files);

                                // find question in form and add the answer
                                question.answer = [...files];

                                // console.log(
                                //     '>>>> Saving question answer...',
                                //     question.answer
                                // );

                                if (
                                    response.hasOwnProperty('gallery') &&
                                    response.gallery
                                ) {
                                    question.gallery = response.gallery;
                                }
                            }
                        );
                    }
                } else {
                    if (response.deleteAnswer) {
                        // add delete request to the delete-requests collection
                        try {
                            await this.addToDeleteRequests(submittedFormId, {
                                questionId: question.id,
                                coachId: submittedForm.coachId,
                                clientId: submittedForm.clientId,
                                prop: this.getPropLabel(response) as any,
                                propId: this.getPropId(response),
                                formId: submittedForm.formId,
                            });
                        } catch (error) {
                            console.log(
                                `>>>> Error adding delete request: ${error}`
                            );
                        }

                        // delete the answer from the question
                        question.answer = null;

                        break;
                    }

                    question.answer =
                        response?.value?.value ?? response?.value ?? null;

                    if (
                        response.hasOwnProperty('metric') &&
                        response.metric !== null &&
                        response.metric >= 0 &&
                        question.answer !== null
                    ) {
                        question.metric = response.metric;
                    } else if (
                        response.hasOwnProperty('measurement') &&
                        response.measurement !== null &&
                        response.measurement >= 0 &&
                        question.answer !== null
                    ) {
                        question.measurement = response.measurement;
                    }
                }
            }
        }
        // When all uploads are complete, save the form (doc refs refer to the form and the client form)

        if (assignedFormDocRef) {
            await assignedFormDocRef.set(
                {
                    updatedAt: new Date(),
                    submittedFormId: submittedFormId ?? null,
                    status: 2, // Submitted
                    completed: true,
                },
                { merge: true }
            );
        }

        // When all uploads are complete, save the form (doc refs refer to the form and the client form)
        const submissionDocPayload = {
            ...submittedForm,
            submittedFormId,
            ...additionalData,
            created: new Date(),
        };

        if (isPreviouslySubmitted) {
            delete submissionDocPayload['created'];
            submissionDocPayload['updatedAt'] = new Date();
        }

        await submissionDocRef.set(submissionDocPayload as any, {
            merge: true,
        });

        console.log('>>>> Saving form...', submittedFormId);

        return submittedFormId;
    }

    //-----------------------------------------------------
    // Standard Form
    //-----------------------------------------------------

    public submitStandardForm(
        submittedForm: any,
        responses: any,
        existingSubmissionId: string | null = null,
        hasFileUpload: boolean = false,
        submissionCollection: string = 'standard-form-submissions',
        formCollection: string = 'standard-forms'
    ): Promise<any> {
        const submittedFormId: string =
            existingSubmissionId ?? this.firestore.createId();

        let hasExistingSubmissionId: boolean = false;
        if (existingSubmissionId) {
            hasExistingSubmissionId = true;
        }

        // Get reference for form submission
        const submittedFormDocRef = this.firestore
            .collection('users')
            .doc(submittedForm.coachId)
            .collection('users')
            .doc(submittedForm.clientId)
            .collection(submissionCollection)
            .doc(submittedFormId).ref;

        const assignedFormDocRef = this.firestore
            .collection('users')
            .doc(submittedForm.coachId)
            .collection('users')
            .doc(submittedForm.clientId)
            .collection(formCollection)
            .doc(submittedForm.formId).ref;

        const uploadPath = `${submittedForm.coachId}/users/${submittedForm.clientId}/${submissionCollection}/${submittedFormId}`;

        // console.log('>>>> Submitting standard form...', submittedFormId);

        return hasFileUpload
            ? this.submitFormWithFileUploads(
                  submittedForm,
                  responses,
                  {},
                  submittedFormId,
                  uploadPath,
                  submittedFormDocRef,
                  assignedFormDocRef,
                  hasExistingSubmissionId
              )
            : this.submitFormWithoutUploads(
                  submittedForm,
                  responses,
                  {},
                  submittedFormId,
                  submittedFormDocRef,
                  assignedFormDocRef,
                  hasExistingSubmissionId
              );
    }

    getFormSubmission(
        coachId: string,
        clientId: string,
        submittedFormId: string,
        collectionName: string = 'standard-form-submissions'
    ) {
        if (!submittedFormId) {
            return of(null);
        }

        return this.firestore
            .collection('users')
            .doc(coachId)
            .collection('users')
            .doc(clientId)
            .collection(collectionName)
            .doc(submittedFormId)
            .get()
            .pipe(map((doc: any) => doc.data()));
    }

    //-----------------------------------------------------
    // Check-ins
    //-----------------------------------------------------

    public async submitCheckIn(
        submittedForm: any,
        responses: any,
        existingSubmissionId: string | null = null,
        hasFileUpload: boolean = false,
        submissionCollection: string = 'checkin-form-submissions'
    ): Promise<any> {
        const submittedFormId: string =
            existingSubmissionId ?? this.firestore.createId();

        // Get reference for form submission
        const checkinDocRef = this.firestore
            .collection('users')
            .doc(submittedForm.coachId)
            .collection('users')
            .doc(submittedForm.clientId)
            .collection(submissionCollection)
            .doc(submittedFormId).ref;

        const checkInMetadata = {
            status: 1, // Awaiting review
            weekNo: submittedForm.weekNo ?? 0,
        };

        const uploadPath = `${submittedForm.coachId}/users/${submittedForm.clientId}/gallery/${submittedFormId}`;
        return hasFileUpload
            ? await this.submitFormWithFileUploads(
                  submittedForm,
                  responses,
                  checkInMetadata,
                  submittedFormId,
                  uploadPath,
                  checkinDocRef,
                  null
              )
            : await this.submitFormWithoutUploads(
                  submittedForm,
                  responses,
                  checkInMetadata,
                  submittedFormId,
                  checkinDocRef,
                  null
              );
    }

    //-----------------------------------------------------
    // Public Form
    //-----------------------------------------------------

    // A public form can go to two collections depending on its type
    // a prospect form goes to the prospects-form-submissions collection for display on prospects taskboard
    // a public form goes to the public-form-submissions collection for form review in normal way
    public async submitPublicForm(
        submittedForm: any,
        responses: any,
        existingSubmissionId: string | null = null,
        hasFileUpload: boolean = false
    ): Promise<any> {
        const submittedFormId: string =
            existingSubmissionId ?? this.firestore.createId();

        // if the form is prospect type then collection should be prospects
        const submissionCollection = submittedForm.isProspect
            ? 'prospect-form-submissions'
            : 'public-form-submissions';

        // TODO: on document created in prospect-form-submissions, create a card in To Review list of prospect scrumboard

        // Get reference for form submission
        const submittedFormDocRef = this.firestore
            .collection('users')
            .doc(submittedForm.coachId)
            .collection(submissionCollection)
            .doc(submittedFormId).ref;

        const uploadPath = `${submittedForm.coachId}/${submissionCollection}/${submittedFormId}`;

        return hasFileUpload
            ? await this.submitFormWithFileUploads(
                  submittedForm,
                  responses,
                  {},
                  submittedFormId,
                  uploadPath,
                  submittedFormDocRef,
                  null
              )
            : await this.submitFormWithoutUploads(
                  submittedForm,
                  responses,
                  {},
                  submittedFormId,
                  submittedFormDocRef,
                  null
              );
    }

    //-----------------------------------------------------
    // Helpers
    //-----------------------------------------------------
    toFormGroup(questions: QuestionBase<any>[]) {
        const group: any = {};

        questions.forEach((question: QuestionBase<any>) => {
            if (question.controlType === 'FILE_UPLOAD') {
                group[question.id] = question.required
                    ? new FormControl<File[]>(null, [
                          FileUploadValidators.filesLimit(
                              environment.FILES_LIMIT
                          ),
                          Validators.required,
                      ])
                    : new FormControl<File[]>(
                          null,
                          FileUploadValidators.filesLimit(
                              environment.FILES_LIMIT
                          )
                      );
            } else {
                group[question.id] = question.required
                    ? new FormControl(null, Validators.required)
                    : new FormControl(null);
            }
        });

        return new FormGroup(group);
    }

    updateForm(submittedFormId: string, collectionName: string, payload: any) {
        return this.firestore
            .collectionGroup(collectionName, ref =>
                ref.where('submittedFormId', '==', submittedFormId)
            )
            .get()
            .pipe(
                take(1),
                map((doc: any) =>
                    doc.docs[0].ref.update(payload, { merge: true })
                )
            );
    }

    async addToDeleteRequests(
        submittedFormId: string,
        payload: {
            questionId: string;
            coachId: string;
            clientId: string;
            prop: 'metric' | 'measurement' | 'gallery';
            propId: string;
            formId: string;
            file?: string | undefined;
        }
    ) {
        await this.firestore.collection('delete-requests').add({
            submittedFormId,
            requestedAt: new Date(),
            ...payload,
        });
    }

    getPropId(response: any): string {
        if (
            response.hasOwnProperty('metric') &&
            response.metric >= 0 &&
            response.metric !== null
        ) {
            return response.metric;
        } else if (
            response.hasOwnProperty('measurement') &&
            response.measurement >= 0 &&
            response.measurement !== null
        ) {
            return response.measurement;
        } else {
            return null;
        }
    }

    getPropLabel(response: any): string {
        if (
            response.hasOwnProperty('metric') &&
            response.metric >= 0 &&
            response.metric !== null
        ) {
            return 'metric';
        } else if (
            response.hasOwnProperty('measurement') &&
            response.measurement >= 0 &&
            response.measurement !== null
        ) {
            return 'measurement';
        } else if (response.hasOwnProperty('gallery') && response.gallery) {
            return 'gallery';
        }
    }

    // Get coach logo url
    getCoachLogo(coachId: string): Observable<string> {
        return this._httpClient
            .get(
                `${environment.firebaseConfig.functions.host}/getCoachLogo?coach=${coachId}`
            )
            .pipe(
                take(1),
                map((res: any) => res?.logo ?? null)
            );
    }

    async markFormAsReviewed(
        submittedFormId: string,
        formCollectionName: string,
        status: number
    ): Promise<any> {
        try {
            let changes = false;
            const batch = this.firestore.firestore.batch();

            // if its not 'public-form' we need to do this so that status on form over for client is updated
            if (!formCollectionName.toLowerCase().includes('public')) {
                const formCollectionRef = this.firestore.collectionGroup(
                    `${formCollectionName}s`,
                    ref => ref.where('submittedFormId', '==', submittedFormId)
                );

                const formCollectionSnapshot = await formCollectionRef
                    .get()
                    .toPromise();

                if (!formCollectionSnapshot.empty) {
                    const formCollectionDoc = formCollectionSnapshot.docs[0];

                    // update with status and last updated
                    batch.update(formCollectionDoc.ref, {
                        status,
                        updatedAt: new Date(),
                    });

                    changes = true;
                }
            }

            // submissions collection status is always updated
            const submissionCollectionRef = this.firestore.collectionGroup(
                `${formCollectionName}-submissions`,
                ref => ref.where('submittedFormId', '==', submittedFormId)
            );

            const submissionCollectionSnapshot = await submissionCollectionRef
                .get()
                .toPromise();
            console.log(
                'Submission collection snapshot:',
                submissionCollectionSnapshot
            );

            if (!submissionCollectionSnapshot.empty) {
                const submissionCollectionDoc =
                    submissionCollectionSnapshot.docs[0];

                // update with status and last updated
                batch.update(submissionCollectionDoc.ref, {
                    status,
                    updatedAt: new Date(),
                });

                changes = true;
            }

            // is there anything to commit
            if (!changes) {
                return { success: false };
            }

            await batch.commit();

            return { success: true };
        } catch (err) {
            console.error('Error in markFormAsReviewed:', err);
        }
    }

    async deleteFormSubmission(
        submittedFormId: string,
        formCollectionName: string
    ): Promise<void> {
        try {
            const formRef = await this.firestore
                .collectionGroup(formCollectionName, ref =>
                    ref.where('submittedFormId', '==', submittedFormId)
                )
                .get()
                .toPromise();

            if (!formRef.empty) {
                const formDoc = formRef.docs[0];
                console.log('Document to delete:', formDoc.id);
                return formDoc.ref.delete();
            } else {
                console.log(
                    'No document found with submittedFormId:',
                    submittedFormId
                );
            }
        } catch (error) {
            console.error('Error deleting form submission:', error);
            throw error;
        }
    }
}
