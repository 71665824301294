<div class="flex flex-col flex-auto">
    <!-- Close button -->
    <div class="flex items-center justify-between p-6 md:p-8">
        <div class="text-2xl font-semibold tracking-tight"></div>

        <button mat-icon-button [routerLink]="['../../']">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <video #videoPlayer width="640" height="360" controls autoplay>
        <source src="assets/videos/workout-complete.mov" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
</div>
