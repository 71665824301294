import { Component } from '@angular/core';
import { StatusBar } from '@capacitor/status-bar';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor() {
        this.initializeApp();
    }

    async initializeApp() {
        StatusBar.setOverlaysWebView({ overlay: true });
        await StatusBar.hide();
    }
}
