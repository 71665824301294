import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseAlertModule } from '@fuse/components/alert';
import { FuseDrawerModule } from '@fuse/components/drawer';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, FuseAlertModule, FuseDrawerModule],
    exports: [CommonModule, FormsModule, ReactiveFormsModule, FuseAlertModule, FuseDrawerModule],
})
export class SharedModule {}
