import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FuseAlertModule } from '@fuse/components/alert';
import { ErrorSnackComponent } from './error-snack/error-snack.component';
import { SuccessSnackComponent } from './success-snack/success-snack.component';
import { InfoSnackComponent } from './info-snack/info-snack.component';
import { WarningSnackComponent } from './warning-snack/warning-snack.component';

@NgModule({
    declarations: [
        ErrorSnackComponent,
        SuccessSnackComponent,
        InfoSnackComponent,
        WarningSnackComponent,
    ],
    imports: [CommonModule, FuseAlertModule, MatSnackBarModule],
})
export class SnackbarModule {}
