<div
    class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6 overflow-y-auto"
    *ngIf="form"
>
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
    >
        <div class="text-lg font-medium">Add Service</div>
        <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"
            ></mat-icon>
        </button>
    </div>

    <form
        [formGroup]="form"
        class="flex flex-col px-8 pt-10 bg-card shadow rounded overflow-hidden"
    >
        <!-- Name -->
        <div class="flex">
            <mat-form-field class="flex-auto w-full fuse-mat-emphasized-affix">
                <mat-label>Name</mat-label>
                <input
                    matInput
                    [formControlName]="'name'"
                    [placeholder]="'Enter name of service'"
                />
                <mat-error *ngIf="form.get('name').hasError('required')">
                    Name is required.
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Duration -->
        <div class="flex flex-row gap-2">
            <mat-form-field class="flex-auto w-1/2 fuse-mat-emphasized-affix">
                <mat-label>Duration</mat-label>
                <input
                    matInput
                    type="number"
                    min="1"
                    max="1000"
                    [formControlName]="'duration'"
                />
                <mat-error *ngIf="form.get('duration').hasError('required')">
                    Plan duration is required
                </mat-error>
                <mat-error
                    *ngIf="
                        form.get('duration').hasError('max') ||
                        form.get('duration').hasError('min')
                    "
                >
                    Duration must be between 1 - 1000.
                </mat-error>

                <mat-hint>How long should the service last</mat-hint>
                <mat-select matSuffix [formControlName]="'durationType'">
                    <mat-option
                        [value]="option.value"
                        *ngFor="let option of planDurationOptions"
                        >{{ option.label }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Prices -->
        <ng-container *ngIf="service?.prices?.length > 0">
            <!-- All Prices -->
            <div class="flex flex-col">
                <div class="font-semibold">
                    {{ service.prices?.length }} total prices available:
                </div>
                <div class="mt-4 space-y-2">
                    <ul>
                        <div
                            class="flex min-w-64 mt-2"
                            *ngFor="let price of service.prices; let i = index"
                        >
                            <div class="min-w-100" [floatLabel]="'always'">
                                <b>£{{ price.amount }}</b> billed
                                {{ price.paymentFrequency | paymentFrequency }}
                            </div>
                            <button
                                mat-stroked-button
                                class="ml-4"
                                (click)="deletePrice(i, price)"
                            >
                                <mat-icon
                                    [svgIcon]="'heroicons_outline:x'"
                                ></mat-icon>
                                <span
                                    class="ml-2 mr-1"
                                    [matTooltip]="'Delete price'"
                                    >Delete</span
                                >
                            </button>
                        </div>
                    </ul>
                </div>
            </div>
        </ng-container>

        <div class="w-full">
            <div
                class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5 bg-gray-50 dark:bg-gray-700"
            >
                <button type="button" mat-button (click)="cancel()">
                    Cancel
                </button>
                <button
                    class="px-8"
                    mat-flat-button
                    [disabled]="form.invalid || loading"
                    [color]="'primary'"
                    (click)="save()"
                    type="button"
                >
                    <span *ngIf="!loading">Save</span>
                    <mat-progress-spinner
                        [diameter]="24"
                        [mode]="'indeterminate'"
                        *ngIf="loading"
                    >
                    </mat-progress-spinner>
                </button>
            </div>
        </div>
    </form>
</div>
