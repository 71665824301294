import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    Exercise,
    ExerciseLabel,
    ExerciseLink,
    Set,
} from 'app/models/exercise';

@Component({
    selector: 'preview-exercise',
    templateUrl: './preview-exercise.component.html',
    styleUrls: ['./preview-exercise.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewExerciseComponent implements OnInit {
    @Input()
    title: string;

    @Input()
    sets: Set[];

    @Input()
    instructions: string;

    @Input()
    exerciseLink: ExerciseLink;

    @Input()
    clientNotes: string;

    @Input()
    exercise: Exercise;

    constructor() {}

    ngOnInit(): void {
        console.log(`Ex: ${this.exercise}`);
    }
}
