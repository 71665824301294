import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoachServicePrice } from '../../coach-service.model';

@Component({
    selector: 'select-price',
    templateUrl: './select-price.component.html',
    styleUrls: ['./select-price.component.scss'],
})
export class SelectPriceComponent implements OnInit {
    form: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<SelectPriceComponent>
    ) {}

    prices: CoachServicePrice[] = [];

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            price: ['', [Validators.required]],
        });
        this.prices = this.data.prices;
    }

    select() {
        if (!this.form.valid) {
            return;
        }

        this.matDialogRef.close({ selected: this.form.value.price });
    }
}
