import { Injectable } from '@angular/core';
import {
    AngularFireStorage,
    AngularFireStorageReference,
    AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { Observable, of, from, map, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private storage: AngularFireStorage) {}

    /**
     * File upload(s)
     */

    /**
     * File upload as base64 string
     * @param file - File to upload inc. metadata
     * @param path - path to upload to
     * @param format - file format default is 'base64'
     * @returns - Observable of upload task
     */
    uploadStringAsFile(
        file: any,
        path: string
    ): Observable<UploadTaskSnapshot> {
        if (!file) return of(null);

        const ref = this.storage.ref(path);

        let fileData = null;
        try {
            fileData = file.data.replace(/^data:(.*,)?/, ''); // clean up data
        } catch (e) {
            fileData = file.replace(/^data:(.*,)?/, ''); // clean up data
        }

        const task: AngularFireUploadTask = ref.putString(fileData, 'base64', {
            customMetadata: {
                name: file?.name,
                type: file?.type,
                size: file?.size,
            },
        });

        return from(task);
    }

    /**
     * File upload as blob or file
     * @param file
     * @param path
     * @param meta
     * @returns
     */
    uploadFile(
        file: any,
        path: string,
        meta: any
    ): {
        uploadTask: AngularFireUploadTask;
        fileRef: AngularFireStorageReference;
    } {
        try {
            const fileRef: AngularFireStorageReference = this.storage.ref(path);
            const uploadTask: AngularFireUploadTask = fileRef.put(file, meta);

            // return upload task for percentage changes
            return { uploadTask, fileRef };
        } catch (e) {
            console.error(e.message);
        }
    }

    async uploadFilePromise(
        file: any,
        path: string,
        meta: any
    ): Promise<AngularFireUploadTask> {
        try {
            const fileRef: AngularFireStorageReference = this.storage.ref(path);
            const uploadTask: AngularFireUploadTask = fileRef.put(file, meta);

            // return upload task for percentage changes
            return uploadTask;
        } catch (e) {
            console.error(e.message);
        }
    }

    /**
     * Remove file from storage
     * @param url
     */
    delete(url: string): Observable<any> {
        return this.storage
            .refFromURL(url)
            .delete()
            .pipe(
                map((res) => {
                    return true;
                }),
                catchError((err) => {
                    console.error(err.message);
                    return of(false);
                })
            );
    }

    /**
     * download file from storage
     * @param filePath
     * @returns
     */
    download(filePath: string): Observable<string | null> {
        // emits an observable containing download URL
        // pipe available for displaying images
        // * <img [src]="filePath | getDownloadURL" />
        try {
            const ref: AngularFireStorageReference =
                this.storage.refFromURL(filePath);
            return ref.getDownloadURL();
        } catch (e) {
            console.error(e.message);
        }
    }

    getDownloadURL(task: any): Observable<string | null> {
        return task.snapshotChanges().pipe(
            map((res: any) => {
                return res?.ref?.getDownloadURL();
            })
        );
    }

    getImgSrc(filePath: string): Observable<string | null> {
        // emits an observable containing download URL
        // pipe available for displaying images
        // * <img [src]="filePath | getDownloadURL" />
        try {
            const ref = this.storage.ref(filePath);

            return ref.getDownloadURL();
        } catch (e) {
            console.error(e.message);
        }
    }

    wait = (amount = 0) =>
        new Promise((resolve) => setTimeout(resolve, amount));
}
