export interface Duration {
    amount: number;
    type: DurationType;
}

export enum DurationType {
    DAYS = 1,
    WEEKS = 2,
    MONTHS = 3,
    NO_END_DATE = 100,
}
