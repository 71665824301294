import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'smarter-info-snack',
    templateUrl: './info-snack.component.html',
})
export class InfoSnackComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: { title: string; message: string }
    ) {}
}
